import React, { Component } from 'react';
import {Chart} from '../components/Chart';
import DateSelector from '../components/DateSelector';
import Selector from '../components/Selector';
import {Data} from '../components/Data';

import HighchartsReact from "highcharts-react-official";
import highcharts from 'highcharts'
import  Select  from 'react-select';

var data = [
    ['id', 'date', 'sell', 'salesman_id', 'category'],
    [1, '01-01-2020', 17, 2, 5],
    [2, '01-01-2020', 23, 1, 5],
    [3, '01-01-2020', 42, 33, 3],
    [1, '01-03-2020', 73, 2, 3],
    [1, '01-02-2020', 4, 2, 3],
    [2, '01-02-2020', 33, 33, 12],
    [3, '01-02-2020', 82, 56, 17],
    [3, '01-03-2020', 25, 23, 17],
    [2, '01-03-2020', 43, 2, 5]
]
var xIndex = 1
var yIndex = 2



// var datasets = []

function setUniqueValues(data, params) {
    var unique = {}
    for (var row in data) {
        // console.log(row)
        for (var p in params) {
            // console.log(p)
            if (!unique[p]) unique[p] = []
            // console.log(row)
            unique[p].push(data[row][params[p]])         
        }
        // console.log(unique)
    }
    for (var p in params) {
        unique[p] = unique[p].filter(onlyUnique)       
    }
    console.log(unique)
    return unique
}

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

function calculateSum(data, params) {
    var filteredData = {
        xAxis: {
            categories: []
        },
        yAxis: {
            categories: []
        },
        series: [],
    }
    // console.log(data)
    data = data.slice(1)
   // console.log(data)
    var uniqueValues = setUniqueValues(data, params);
    filteredData.xAxis.categories = uniqueValues.x
    filteredData.yAxis.categories = uniqueValues.y

    if (params.groupBy) {
        // console.log(uniqueValues.groupBy)
        // console.log(uniqueValues.x)
        for (var j = 0; j < uniqueValues.groupBy.length; j++) {
            filteredData.series[j] = {}
            filteredData.series[j].data = []
            filteredData.series[j].name = uniqueValues.groupBy[j]
            
            for (var i = 0; i < uniqueValues.x.length; i++) {
                var value = 0
            // console.log('new x value SUM: ' + i)   

                for (var row in data) {
                    // console.log(data[row][params.x] + ' ' + uniqueValues.x[i])
                    // console.log(data[row][params.groupBy]  + ' ' +  uniqueValues.groupBy[j])
                    if (data[row][params.x] == uniqueValues.x[i] && data[row][params.groupBy] == uniqueValues.groupBy[j]) {
                       // console.log(data[row])
                        console.log('parsed data',parseFloat(data[row][params.y]) )
                        value += parseFloat(data[row][params.y])
                    }
                    filteredData.series[j].data[i] = value
                }
            }              
        }
    } else {
        filteredData.series[0] = {}
        filteredData.series[0].data = []
        filteredData.series[0].name = 'Values'
        
        for (var i = 0; i < uniqueValues.x.length; i++) {
            var value = 0
        // console.log('new x value SUM: ' + i)   

            for (var row in data) {
                // console.log(data[row][params.x] + ' ' + uniqueValues.x[i])
                // console.log(data[row][params.groupBy]  + ' ' +  uniqueValues.groupBy[j])
                if (data[row][params.x] == uniqueValues.x[i]) {
                   //console.log(data[row])
                    value += data[row][params.y]
                }
                filteredData.series[0].data[i] = value
            }
        }              
    }
    console.log(filteredData)
    return filteredData

}



// var datasets = calculateSum(data, {'x': 1, 'y': 2, 'groupBy': 4})
// console.log(datasets)

class Dataset extends Component {

    constructor(props){
        super(props);
        this.state = {
            dataSets: [],
            selectedData: {},
            selectedDataIndex: '',
            nameOptions: [],
            filterOptions: [],
            x: [],
            y: [],
            options: {
                chart: {
                    type: 'column',
                    plotBorderWidth: 1,
                    zoomType: 'xy'
                },
                xAxis: {
                    categories: [1,2,3],
                    title: {
                      text: 'Year'
                   }
                },
                title: {
                    text: 'chart'
                },
                series: [{name: 'test', data: [1,3,5]},{name: 'test2', data: [3,2,6]}]
            }
        }
    }
    getChartData=()=>{
        console.log("Tijana", Data.getDataSet())    
    }
    
    selectData = (e) => {
        console.log(e);
        // let filterOptions = Data.data[e.value].data[0]
        let filterOptions = Data.data[e.value].data[0].map((item, index) => ({
            "value" : index,
            "label" : item
        }))

        
        this.setState({selectedData: Data.data[e.value], filterOptions, selectedDataIndex: e.value}, ()=>{
            console.log("s",this.state.selectedData, this.state.filterOptions, this.state.selectedDataIndex)  
        })
    }
    selectX = (e) => {
        console.log(e);
        this.setState({x: e.value}, ()=>{
            console.log("x",this.state.x) 
        })
    }
    selectY = (e) => {
        console.log(e);
        this.setState({y: e.value}, ()=>{
            console.log("y",this.state.y)
        })
    }
    selectGroupBy = (e) => {
        console.log(e);
        this.setState({groupBy: e.value}, ()=>{
            console.log("groupBy",this.state.groupBy)
        })
    }
    
    getDataNameList(){
        const data = Data.getDataSet()
        const options = data.map((item, index) => ({
          "value" : index,
          "label" : item.name
        }))
        
        this.setState({nameOptions: options})
    }

    submitDataToChart=()=>{
        var chartData = [];
        console.log('Data.data.data', this.state.selectedData)
        // console.log('calculateSum', calculateSum(this.state.selectedData.data, {x: this.state.x, y: this.state.y, groupBy: this.state.groupBy}))
      
        chartData = calculateSum(this.state.selectedData.data, {x: this.state.x, y: this.state.y, groupBy: this.state.groupBy})
        var xAxis = chartData.xAxis;
        var yAxis = chartData.yAxis;
        var series = chartData.series;
        console.log("chartData", chartData, xAxis, yAxis, series);
        this.setState({options: {series: series, xAxis: xAxis, yAxis: yAxis}});
        
    }
    componentDidMount(){
        
        this.getDataNameList(); 
    }
render() {
    return (
        <div className="dataset">
            <h1>Dataset</h1>
            <p>{this.props.name}</p>
            {/* <p>{console.log("this.state",this.state.dataSets)}</p> */}
            <h3>Select File:</h3>
            <Select id="title" options={this.state.nameOptions} onChange={this.selectData}/>
            <h3>Select X Values:</h3>
            <Select options={this.state.filterOptions} onChange={this.selectX}/>
            <h3>Select Y Values:</h3>
            <Select options={this.state.filterOptions} onChange={this.selectY}/>
            <h3>Select Group By:</h3>
            <Select options={this.state.filterOptions} onChange={this.selectGroupBy}/>
            <button className="show-chart" onClick={this.submitDataToChart}>Show Chart</button>
            <div className='chart-container-sales'>
                <HighchartsReact highcharts={highcharts} options={this.state.options} allowChartUpdate={true}
                updateArgs={[true, true, true]}/>
            </div>
            
        </div>
      
    )
  }
}

export default Dataset;