import React from "react";

export const Data = {
  data: [],
  addDataSet(e){
    this.data.push(e);
  },
  removeDataSet(index){
    this.data.slice(index, 1);
  },
  getDataSet(){
    return this.data;
  },
  getDataSetByRow(rowId){
    
    return this.data[rowId]
  }
    
}