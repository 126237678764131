import React, { Component } from 'react';
import Service from '../components/Service';


import ImportCustom from '../components/ImportCustom';
import ImportCustomCsv from '../components/ImportCustomCsv';
import ImportCustomExcel from '../components/ImportCustomExcel';


function ToRender(props) {
  const component = props.component;

  if(component === 'custom'){
    return <ImportCustom />;
  }
  if(component === 'custom-csv'){
    return <ImportCustomCsv />;
  }
  if(component === 'custom-excel'){
    return <ImportCustomExcel />;
  }


  
  return <p>Choose action...</p>
}

class Import extends Component {
constructor() {
    super();
    this.state = {
      name: '',
      component: false,
    };
  }
  handleName = (event) => {
    console.log(event.target.value);
    this.setState({name: event.target.value});
  }

  render() {
    return (
      <div>
        <div className="submenu">
            {/* <h2>SELL IN</h2>
            <ul>
                <li onClick={()=>{this.setState({component: 'mis'})}}>Import Otpremnice</li>
                <li onClick={()=>{this.setState({component: 'mis-povratnice'})}}>Import Povratnice</li>
                <li onClick={()=>{this.setState({component: 'products'})}}>Import Products</li>
                <li onClick={()=>{this.setState({component: 'customers'})}}>Import Customers</li>
                <li onClick={()=>{this.setState({component: 'stores'})}}>Import Stores</li>
                <li onClick={()=>{this.setState({component: 'partners'})}}>Import Partners</li>
                <li onClick={()=>{this.setState({component: 'geo-locations'})}}>Import Geo Locations</li>
                <li onClick={()=>{this.setState({component: 'business-associates'})}}>Import Associates</li>
                
            </ul> */}
            <h2>Select file type</h2>
            <ul>
                {/* <li onClick={()=>{this.setState({component: 'sell-out'})}}>Import SellOut</li>
                <li onClick={()=>{this.setState({component: 'sell-out-multi'})}}>Import SellOut MULTI</li>
                <li onClick={()=>{this.setState({component: 'sell-out-dev'})}}>Import SELL OUT DEV</li> */}
                <li onClick={()=>{this.setState({component: 'custom'})}}>Import Text</li>
                <li onClick={()=>{this.setState({component: 'custom-csv'})}}>Import CSV</li>
                <li onClick={()=>{this.setState({component: 'custom-excel'})}}>Import Excel</li>

                
            </ul>
        </div>
        <div className="right-content">
            
            <ToRender component={this.state.component}/>
        </div>
      </div>
    );
  }

}

export default Import;