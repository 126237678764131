import React, { Component } from 'react';

const dates = {
    years: [1978, 1979, 2015,2016,2017,2018,2019,2020,2021,2022,2023],
    months: [1,2,3,4,5,6,7,8,9,10,11,12]
}

function getDaysInMonth (month, year) { 
    let r = new Date(year, month, 0).getDate(); 
    
    var days = [];
    for(var i = 0; i< r; i ++){
        days.push(i + 1);
    }
    console.log(days, month, year)
    return days;
}

function appendLeadingZeroes(n){
  if(n <= 9){
    return "0" + n;
  }
  return n
}



class DateSelector extends Component {

	constructor(props) {
	    super(props);
        this.state = {
            year: this.props.defaultYear,
            month: this.props.defaultMonth,
            day: 1,
            days: []
        }
	}

    componentDidMount(){
        this.updateDays();
        setTimeout(()=>{this.sendDate()}, 1000);
    }

    sendDate=()=>{
        // call the parent component to update the dates
        let date = new Date(this.state.year, this.state.month - 1, this.state.day)
        let formatted_date = date.getFullYear() + "-" + appendLeadingZeroes(date.getMonth() + 1) + "-" + appendLeadingZeroes(date.getDate());
        this.props.onChange(formatted_date);
    }

    setYear=(y)=>{
        this.setState({
            year: y
        },()=>{
            this.sendDate();
        })
    }

    setMonth=(m)=>{
        this.setState({
            month: m
        },()=>{
            this.sendDate();
        })
    }

    setDay=(d)=>{
        this.setState({
            day: d
        },()=>{
            this.sendDate();
        })
    }

    changeYear=(event)=>{
        this.setState({year: parseInt(event.target.value)},()=>{
            this.updateDays();
            this.sendDate();
        });
    }
    changeMonth=(event)=>{
        this.setState({month: parseInt(event.target.value)},()=>{
            this.updateDays();
            this.sendDate();
        });

        // this.updateDays();
    }
    changeDay=(event)=>{
        this.setState({day: event.target.value},()=>{
            this.sendDate();
        });
    }

    updateDays=()=>{
        console.log(this.state.month,this.state.year)
        this.setState({days: getDaysInMonth(this.state.month, this.state.year)})
    }

  render() {
    return (
      <div className="date-selector">
        {this.props.label}

        {/* year */}
        <select value={this.state.year} onChange={this.changeYear}>
                    {dates.years.map(function(item, index){
                    return(
                        <option value={item} key={index}>{item}</option>
                    )},this
                    )};
        </select>
        {/* month */}
        <select value={this.state.month} onChange={this.changeMonth}>
                    {dates.months.map(function(item, index){
                    return(
                        <option value={item} key={index}>{item}</option>
                    )},this
                    )};
        </select>
        {/* days */}
        <select value={this.state.day} onChange={this.changeDay}>
                    {this.state.days.map(function(item, index){
                    return(
                        <option value={item} key={index}>{item}</option>
                    )},this
                    )};
        </select>
      </div>
      
    )
  }
}

export default DateSelector;