import React, { Component } from 'react';


class MyAccount extends Component {

	constructor(props) {
	    super(props);
	}

  render() {
    return (
      <div>
        <h1>My Account</h1>
      </div>
      
    )
  }
}

export default MyAccount;