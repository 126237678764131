
function dist(a, b){
	return Math.sqrt((Math.pow(a.x - b.x, 2)) + (Math.pow(a.y - b.y, 2)));
}
function isNeighbourCloser(store, master, neighbour){
    let ds = dist(store, neighbour);
    let dm = dist(master, neighbour);
    if(ds < dm){
        return true;
    }
    return false;
}

function inside(point, vs) {
    // ray-casting algorithm based on
    // https://wrf.ecse.rpi.edu/Research/Short_Notes/pnpoly.html/pnpoly.html
    
    //var x = point[0], y = point[1];
    var x = point.x, y = point.y;
    
    var inside = false;
    for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
        var xi = vs[i][0], yi = vs[i][1];
        var xj = vs[j][0], yj = vs[j][1];
        
        var intersect = ((yi > y) != (yj > y))
            && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
    }
    
    return inside;
};


//function inside(point, vs) {
    // ray-casting algorithm based on
    // https://wrf.ecse.rpi.edu/Research/Short_Notes/pnpoly.html/pnpoly.html
    // var x = point[0], y = point[1];
    // var inside = false;
    // for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
    //     var xi = vs[i][0], yi = vs[i][1];
    //     var xj = vs[j][0], yj = vs[j][1];
    //     var intersect = ((yi > y) != (yj > y))
    //         && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
    //     if (intersect) inside = !inside;
    // }
    //return 1;
//};


class TerritoriesClass {

    constructor(stores, polygon) {
        this.stores = stores;
        this.polygon = polygon;
    }

    numberOfStores = 0;
    numberOfSections = 5;
	stores = []
    fourPoints = {};
	centerPoint = {}
	sections= ['north','east','south', 'west', 'center'];
	populated= [];
	sectionsCount= {north: 0, east: 0, south: 0, west: 0, center: 0};
	storesCount= null;//Math.floor(this.numberOfStores / 5);
	lockedStores= [];
	counter= 0;
	resolvedSections= [];
	neighbour= 0;
	lastNeighbour= 0;
	resolvedPoints= [];


    createRoute(day, week){
        //console.log('class poly', this.polygon)
        // reset resolved sections
        this.resolvedSections = [];
        //this.stores = [];
        //this.fourPoints = [];

        // assign stores to this
        //this.stores = stores;

        // get number of stores
        this.numberOfStores = this.stores.length;
        // get how many stores should be in one section
        this.storesCount = Math.floor(this.numberOfStores / 5);
        
        // initialize the main object
		this.fourPoints = this.getStartingPoints();
        
        // assign stores evenly to sections
		this.assignStores();  
        //console.log('assigned', this.fourPoints, this.stores.length, this.storesCount);

        this.sections.map((item, index)=>{
            this.fourPoints[item].stores = this.spreadStoresBySales(this.fourPoints[item].stores);
            ////console.log('spbs', this.spreadStoresBySales(this.fourPoints[item].stores));
        })
            
        
        // display properties
        let rf = [];

        // set collors from index.css classes
        if(day == 'all'){
            let colors = ['S1A1','S1A2','S1A5','S1A6','S2A2']
            this.sections.map((section, i)=>{
                let r = this.fourPoints[section].stores;
                r.map((item, index)=>{
                    item.lng = item.longitude;
                    item.lat = item.latitude;
                    // assign class for color
                    item.class = "leaflet-marker-"+colors[i];
                    item.label = item.outlet_name + '\r ' + item.store_address;
                    rf.push(item);

                })
            })
        }else{
            //this.sections.map((section, i)=>{
                //console.log('dw', day, week);
                let r = this.fourPoints[this.sections[day]].stores;
                ////console.log('tsd',this.sections[day], r)
                r.map((item, index)=>{
                    if(week === 'all'){
                        item.lng = item.longitude;
                        item.lat = item.latitude;
                        // assign class for color
                        item.class = "leaflet-marker-S1A1";
                        item.label = item.outlet_name + '\r ' + item.store_address;
                        rf.push(item);
                    }else{
                        if(item.weeks.includes(parseInt(week))){
                            ////console.log('week', parseInt(week), item.weeks);
                            item.lng = item.longitude;
                            item.lat = item.latitude;
                            // assign class for color
                            item.class = "leaflet-marker-S1A1";
                            item.label = item.outlet_name + '\r ' + item.store_address;
                            rf.push(item);
                        }
                    }

                })
            //})
        }
        //console.log('stores passed to map', rf);
        return rf;
    }

    

    getStartingPoints(){

		let usedPoints = [];
		let fourPoints = {north: null, east: null, south: null, west: null, center: null};
		
        // find NESW 4 points
        // get bounding box middle points for NESW
        let bb = {n: null, e: null, s: null, w: null};
		this.stores.map((item, index)=>{

			if( !bb.n || item.y < bb.n){
					bb.n = item.y;
			}
            if( !bb.e || item.x > bb.e){
					bb.e = item.x;
			}
            if( !bb.w || item.x < bb.w){
					bb.w = item.x;
			}
            if( !bb.s || item.y > bb.s){
					bb.s = item.y;
			}	
		})

        // assign xy coordinates to four points (main object) and initialize stores array for each
        fourPoints.north = {x: (bb.e + bb.w) / 2, y: bb.n, stores: []};
        fourPoints.east = {x: bb.e, y: (bb.n + bb.s) / 2, stores: []};
        fourPoints.south = {x: (bb.e + bb.w) / 2, y: bb.s, stores: []};
        fourPoints.west = {x: bb.w, y: (bb.n + bb.s) / 2, stores: []};

        
        
		// find center point
		let fp = [fourPoints.north, fourPoints.east, fourPoints.south, fourPoints.west];
		let cx = 0;
		let cy = 0;
		fp.map((item, index)=>{
			cx += item.x;
			cy += item.y;
		})

		this.centerPoint = {x: cx/4, y: cy/4};

        // add center point to the main object
		fourPoints.center = {x: this.centerPoint.x, y: this.centerPoint.y, stores: []}; 

		return fourPoints;

	}

    getStartingPointsOld(){

		let usedPoints = [];
		let fourPoints = {north: null, east: null, south: null, west: null, center: null};
		// finc NSWE 4 points
		// if point is used, dont use it again
		this.stores.map((item, index)=>{

			if( !fourPoints.north || item.y < fourPoints.north.y){
				if(!usedPoints.includes(item.id)){
					fourPoints.north = item;
					usedPoints[0] = item.id;
				}
			}
			if(!fourPoints.east || item.x > fourPoints.east.x){
				if(!usedPoints.includes(item.id)){
					fourPoints.east = item;
					usedPoints[1] = item.id;
				}
			}
			if(!fourPoints.south || item.y > fourPoints.south.y){
				if(!usedPoints.includes(item.id)){
					fourPoints.south = item;
					usedPoints[2] = item.id;
				}
			}
			if(!fourPoints.west ||  item.x < fourPoints.west.x){
				if(!usedPoints.includes(item.id)){
					fourPoints.west = item;
					usedPoints[3] = item.id;
				}
			}

			
		})

        
		// colorize the extreme stores

		let fp = [fourPoints.north, fourPoints.east, fourPoints.south, fourPoints.west];
		let cx = 0;
		let cy = 0;
		fp.map((item, index)=>{
			cx += item.x;
			cy += item.y;

			//$("#"+item.id).css({background: 'red'});
		})

		this.centerPoint = {x: cx/4, y: cy/4};
		fourPoints.center = {x: this.centerPoint.x, y: this.centerPoint.y}; 
		//fourPoints.center.y = centerPoint.y;
		return fourPoints;

	}

    assignStores(){

        let inc = 0;
        let limit = this.storesCount * 4;
        this.stores.map((nn, index)=>{
            if(index < limit){
                let closestStore = this.findClosestStore(this.fourPoints[this.sections[inc]], this.sections[inc])
                if(closestStore){
                    //console.log('closest store section',this.sections[inc]);
                    closestStore.assigned = true;
                    this.fourPoints[this.sections[inc]].stores.push(closestStore);
                    
                }else{
                    //console.log('not found        store !');
                    //nn.group = inc;
                }
            }
            nn.group = inc;
            inc ++;
            if(inc > 3){
                inc = 0;
            }
        })

        this.stores.map((store, index)=>{
            if(!store.assigned){
                this.fourPoints.center.stores.push(store);
                store.group = 4;
            }
        })

       // //console.log('assigned', this.fourPoints);
        

        //let fourPoints = this.fourPoints;
        //let sections = this.sections;
		// this.stores.map((item, index)=>{
		// 	let distance = Infinity;
		// 	for(var i = 0; i < this.numberOfSections; i ++){
		// 		let td = Math.sqrt(((item.x-this.fourPoints[this.sections[i]].x) * (item.x-this.fourPoints[this.sections[i]].x))+((item.y-this.fourPoints[this.sections[i]].y) * (item.y-this.fourPoints[this.sections[i]].y)));
		// 		if(td < distance){

		// 			distance = td;
		// 			item.group = i;
		// 			//$("#"+item.id).css({background: colors[i]});

		// 		}
		// 	}
		// })

	}

    findClosestStore(master, section){
        ////console.log('closest', this.polygon[section], inside({x: .5, y: 2.5}, [[1,1],[1,0],[0,0],[0,2]]));
		let ct = Infinity;
		let rslt;
        //let sp = {x: this.polygon[section][0][0], y: this.polygon[section][0][1]}
        
        if(this.polygon[section]){
            this.stores.map((item, index)=>{
                let pt = {x: item.x, y: item.y}
                ////console.log('inside', inside(pt, this.polygon[section]), pt, this.polygon[section])
                if(this.polygon[section].length < 3){
                    this.polygon[section].map((point)=>{
                        let spp = {x: point[0], y: point[1]}
                        ////console.log('spp',spp);
                        if(dist(spp, pt) < ct && !item.assigned){
                            ////console.log('entered',ct);
                            ct = dist(spp, pt);
                            rslt = item;
                        }
                    })
                }else{
                    let sp = {x: this.polygon[section][0][0], y: this.polygon[section][0][1]}
                    if(dist(sp, pt) < ct && !item.assigned && inside(pt, this.polygon[section])){
                        ct = dist(sp, pt);
                        rslt = item;
                    }
                }
                // if(dist(sp, pt) < ct && !item.assigned && inside(pt, this.polygon[section])){
                //     ct = dist(sp, pt);
                //     rslt = item;
                // }
            })
        }else{
            this.stores.map((item, index)=>{
                let pt = {x: item.x, y: item.y}
                if(dist(master, pt) < ct && !item.assigned){
                    ct = dist(master, pt);
                    rslt = item;
                }
            })
        }


		return rslt;
		

	}

    findClosestStoreOld(master, section){
        //console.log('closest', this.polygon[section]);
		let ct = Infinity;
		let rslt;
        // //console.log('tst',this.stores);
		this.stores.map((item, index)=>{
			let pt = {x: item.x, y: item.y}
			if(dist(master, pt) < ct && !item.assigned){
				ct = dist(master, pt);
				rslt = item;
			}
		})

		return rslt;
		

	}

    assignStoresBck(){

        //let fourPoints = this.fourPoints;
        //let sections = this.sections;
		this.stores.map((item, index)=>{
			let distance = Infinity;
			for(var i = 0; i < this.numberOfSections; i ++){
				let td = Math.sqrt(((item.x-this.fourPoints[this.sections[i]].x) * (item.x-this.fourPoints[this.sections[i]].x))+((item.y-this.fourPoints[this.sections[i]].y) * (item.y-this.fourPoints[this.sections[i]].y)));
				if(td < distance){

					distance = td;
					item.group = i;
					//$("#"+item.id).css({background: colors[i]});

				}
			}
		})

	}

    spreadStores(){


		let bs = this.findBiggestSection();
		let sct = this.fourPoints[bs];//
		let pt = {x: sct.x, y: sct.y};//master of cluster points / point of the biggest section master

		let count = 0;
		//console.log('test', sct.stores.length, this.storesCount);
		let furthestSectionPoints = 0;
		let numOfUnspreadedStores = 0;
		let totalNumOfStoresToSpread = sct.stores.length - this.storesCount;
		let movedStores = 0;
		let flag = false;
        sct.stores.map((store, index)=>{
			if(movedStores < totalNumOfStoresToSpread){
				//let sc = fcn(store, bs);
				var n = this.getTargets(store, bs);
				////console.log('targets', n, bs);
				for(var i = 0; i < n.length; i++){

					//if(i > n.length - 2){
                    if(1){
						this.fourPoints[n[i].name].stores.push(store);
						this.fourPoints[bs].stores.splice(index, 1);
						movedStores++;
						//break;
					}else{
						if(isNeighbourCloser(store, this.fourPoints[bs], this.fourPoints[n[i].name])){

							this.fourPoints[n[i].name].stores.push(store);
							this.fourPoints[bs].stores.splice(index, 1);
							movedStores++;
							//break;
						}
					}
					
				}
				
			}else{
				if(!flag){
					this.resolvedSections.push(bs);
					//console.log('resolvedSections', this.resolvedSections);
					flag = true;
				}
			}
		});

        if(!flag){
            this.resolvedSections.push(bs);
            ////console.log('resolvedSections free', this.resolvedSections);
            flag = true;
        }
		//console.log('length',this.resolvedSections.length, this.sections.length - 1)
		if(this.resolvedSections.length < this.sections.length - 1){
			this.spreadStores();

		}else{
            
			//console.log('spread done', this.fourPoints, {north: this.fourPoints['north'].stores.length,east: this.fourPoints['east'].stores.length,west: this.fourPoints['west'].stores.length,south: this.fourPoints['south'].stores.length, center: this.fourPoints['center'].stores.length});
            let e = [];
            
            ////console.log('weeks fp', this.fourPints)
            
            return this.fourPoints;
            
		}

	}

    findClosestTarget(s){
		let ct = Infinity;
		let rslt;
		this.sections.map((item, index)=>{
			let pt = {x: this.fourPoints[item].x, y: this.fourPoints[item].y}
			if(dist(s, pt) < ct){
				ct = dist(s, pt);
				rslt = item;
			}
		})

		return rslt;
		

	}

    getTargets(store, currentSection){
		let res = [];
		let ds = Infinity;
		this.sections.map((section, index)=>{
			if(section != currentSection && !this.resolvedSections.includes(section)){
				let d = dist(store, this.fourPoints[section]);
				res.push({name: section, distance: d});
			}
		});
		res.sort(function(a, b){return a.distance - b.distance});
		return res;
	}

    


	findBiggestSection(){
        let count = 0;
		let res;
        ////console.log('res sections', this.resolvedSections);
		this.sections.map((section, i)=>{
			if(this.fourPoints[section].stores.length > count && !this.resolvedSections.includes(section)){
				count = this.fourPoints[section].stores.length;
				res = section;
			}
		});
		return res;

		
	}

    fcn(store, currentSection){
        let res;
        let ds = Infinity;
        this.sections.map((section, index)=>{
            if(section != currentSection && !this.resolvedSections.includes(section)){
                let d = dist(store, this.fourPoints[section]);
                if(d < ds){
                    ds = d;
                    res = section;
                }
            }
        });
        return res;
    }


    getGroup(i, length, occ){
        let r = 0
        occ.map((item, index)=>{
            if(i > Math.ceil(length * (item/100))){
                return r;
            }
            r = index;
        })
        return r;
    }

    groupStores(ind,length, occurrence){
        let grp = 0;
			for(var i = 0; i < length; i++){	
				for(var j = 0; j < occurrence.length; j++){
					if(occurrence[j-1]){
						if(occurrence[j-2]){
							if(ind >= Math.ceil(length*(occurrence[j-2] + occurrence[j-1])/100) && ind <= Math.floor(length*(occurrence[j-1]+occurrence[j])/100)){
								// //console.log(ind, j);
								// //console.log('occurrence[j-2] + occurrence[j-1]',Math.ceil(length*(occurrence[j-2] + occurrence[j-1])/100));
								// //console.log('occurrence[j-1]+occurrence[j]',Math.ceil(length*(occurrence[j-1]+occurrence[j])/100));
								grp = j;
							}
						}else{
							if(ind >= Math.ceil(length* occurrence[j-1]/100) && ind <= Math.floor(length*(occurrence[j-1]+occurrence[j])/100)){
								// //console.log(ind, j);
								// //console.log('occurrence[j-1]/100',Math.ceil(length* occurrence[j-1]/100));
								// //console.log('occurrence[j-1]+occurrence[j])/100',Math.ceil(length*(occurrence[j-1]+occurrence[j])/100));
								grp = j;
							}
						}
						
					}else{
						if(ind <= Math.floor(length*occurrence[j]/100)){
							// //console.log(ind, j);
							// //console.log('occurrence[j]/100',Math.ceil(length*occurrence[j]/100));
							grp = j;
						}
					}
					
					
					////console.log('index, group',item.id, j, ind, grp);
				}
			}
		return grp;
	}

    spreadStoresBySales(str){
		// sections.map((section,index)=>{
			let n = 4;

			var resu = [[],[],[],[]]; //we create it, then we’ll fill it

			//var occurrence = [9/31*100, 11/31*100, 5/31*100, 6/31*100];
            ////console.log('on',occurrence[0]+occurrence[1]+occurrence[2]+occurrence[3]);
            ////console.log('occ', occurrence);
            //var occurrence = [9, 23, 20,  49];
            //var occurrence = [9.03225806451613, 23.483870967741936, 20.129032258064516, 29.35483870967742];
            var occurrence = [9, 31, 60, 100];

			str.sort(function(a, b){return parseFloat(b.sales)-parseFloat(a.sales)});

            str.map((item, index)=>{
                //resu[this.getGroup(index, str.length, occurrence)].push(item);
                //resu[this.groupStores(index, str.length, occurrence)].push(item);
                
                 ////console.log('aa', index, Math.floor(str.length * (occurrence[0]/100)),Math.floor(str.length * (occurrence[1]/100)),Math.floor(str.length * (occurrence[2]/100)),Math.floor(str.length * (occurrence[3]/100)))
                if(index < Math.ceil(str.length * (occurrence[0]/100))){
                    resu[0].push(item);
                }
                if(index >= Math.ceil(str.length * (occurrence[0]/100)) && index < Math.ceil(str.length * (occurrence[1]/100))){
                    resu[1].push(item);
                }
                if(index >= Math.ceil(str.length * (occurrence[1]/100)) && index < Math.ceil(str.length * (occurrence[2]/100))){
                    resu[2].push(item);
                }
                if(index >= Math.ceil(str.length * (occurrence[2]/100))){
                    resu[3].push(item);
                }
            })
            //console.log('str', str, resu);
			// for (let line = 0; line < n; line++) {
			// 	let wordsPerLine = Math.ceil(str.length * (occurrence[line]/100));
			// 	for (let i = 0; i < wordsPerLine; i++) {
			// 		let value = {};
            //         let obj = str[i + line * wordsPerLine];
            //         for(let p in obj){

            //             value[p] = obj[p];
            //         }
                    
			// 		if (!value) continue //avoid adding “undefined” values
			// 		if(!resu[line]){
			// 			resu[line] = [];
			// 		}
			// 		resu[line].push(value);
			// 	}
			// }

			
			resu[0].map((item,index)=>{
				item.weeks = [1,2,3,4,5,6,7,8];
			});

			for (var i = 0; i < resu[1].length; i++) {
				if(i % 2 == 0){
					resu[1][i].weeks = [2,4,6,8];
				}else{
					resu[1][i].weeks = [1,3,5,7];
				}

			}
			
            // OLD CODE
			let c = 0;
			for (var i = 0; i < resu[2].length; i++) {
				if(c == 0){
					resu[2][i].weeks = [1,5];
				}else if(c == 1){
					resu[2][i].weeks = [3,7];
				}else if(c == 2){
					resu[2][i].weeks = [4,8];
				}else if(c == 3){
					resu[2][i].weeks = [2,6];
				}
				c++;
				if(c > 3){
					c = 0;
				}
			}

            let sb2 = this.getBoundsEight(resu[2]);
/*
            resu[2].map((s)=>{
                let selected = null;
                let count = resu[2].length;
                let current = 0;
                for(var i=0; i < count; i++){
                    sb2.map((item, index)=>{
                        if(index < 4){ //just four points
                            let dst = Infinity;
                            let ss = null;
                            let cdst = dst;
                            resu[2].map((store)=>{
                                
                                if(!store.weeks){
                                    cdst = dist(store, item);
                                    if(cdst < dst){
                                        console.log('cdst', cdst);
                                        ss = store;
                                        dst = cdst;
                                    }
                                }
                            })
                            if(ss){
                                ss.weeks = [index + 1, index + 5];
                            }
                        }
                    })
                }
                

            })
	*/		
            let d = 1;
            // let sBounds = getAreaBounds(resu[3]);
            // let gStores = [];
            // let usedStores = []
            // let numbers = [0,'n','ne','nw','w','sw','se','s','e']
            // for(var p in sBounds){

            // }

            let sb = this.getBoundsEight(resu[3]);
/*
            resu[3].map((s)=>{
                let selected = null;
                let count = resu[3].length;
                let current = 0;
                for(var i=0; i < count; i++){
                    sb.map((item, index)=>{
                        let dst = Infinity;
                        let ss = null;
                        let cdst = dst;
                        resu[3].map((store)=>{
                            
                            if(!store.weeks){
                                cdst = dist(store, item);
                                if(cdst < dst){
                                    console.log('cdst', cdst);
                                    ss = store;
                                    dst = cdst;
                                }
                            }
                        })
                        if(ss){
                            ss.weeks = [index + 1];
                        }
                    })
                }
                

            })
*/
            // OLD CODE
			resu[3].map((item,index)=>{
				
				item.weeks = [d];

                d++;
				if(d > 8){
					d = 1;
				}
			});
			//console.log('res',resu);

            let stores = [];
			resu.map((i)=>{
                i.map((j)=>{
                    stores.push(j);
                })
            })
			
			return stores;
		// });
	}

    getBoundsEight(stores){

		let usedPoints = [];
		let fourPoints = [];
		
        // find NESW 4 points
        // get bounding box middle points for NESW
        let bb = {n: null, e: null, s: null, w: null};
		stores.map((item, index)=>{

			if( !bb.n || item.y < bb.n){
					bb.n = item.y;
			}
            if( !bb.e || item.x > bb.e){
					bb.e = item.x;
			}
            if( !bb.w || item.x < bb.w){
					bb.w = item.x;
			}
            if( !bb.s || item.y > bb.s){
					bb.s = item.y;
			}	
		})

        // assign xy coordinates to four points (main object) and initialize stores array for each
        fourPoints.push({x: (bb.e + bb.w) / 2, y: bb.n, stores: []});
        fourPoints.push({x: bb.e, y: (bb.n + bb.s) / 2, stores: []});
        fourPoints.push({x: (bb.e + bb.w) / 2, y: bb.s, stores: []});
        fourPoints.push({x: bb.w, y: (bb.n + bb.s) / 2, stores: []});
        fourPoints.push({x: bb.w, y: bb.n, stores: []});
        fourPoints.push({x: bb.e, y: bb.n, stores: []});
        fourPoints.push({x: bb.e, y: bb.s, stores: []});
        fourPoints.push({x: bb.w, y: bb.s, stores: []});



		return fourPoints;

	}

    // fcb(bound, stores){
    //     let res;
    //     let ds = Infinity;
    //     this.stores.map((store, index)=>{
    //         if(!usedStores.includes(store)){
    //             let d = dist(store, bound);
    //             if(d < ds){
    //                 ds = d;
    //                 res = store;
    //             }
    //         }
    //     });
    //     return res;
    // }

    getAreaBounds(stores){

        
        // find NESW 4 points
        let obj = {};
        // get bounding box middle points for NESW
        let bb = {n: null,ne: null, e: null, se: null, s: null, sw: null, w: null, nw: null};
		stores.map((item)=>{

			if( !bb.n || item.y < bb.n){
					bb.n = item.y;
			}
            if( !bb.e || item.x > bb.e){
					bb.e = item.x;
			}
            if( !bb.w || item.x < bb.w){
					bb.w = item.x;
			}
            if( !bb.s || item.y > bb.s){
					bb.s = item.y;
			}	
		})



        // assign xy coordinates to four points (main object) and initialize stores array for each
        bb.north = {x: (bb.e + bb.w) / 2, y: bb.n, stores: []};
        bb.east = {x: bb.e, y: (bb.n + bb.s) / 2, stores: []};
        bb.south = {x: (bb.e + bb.w) / 2, y: bb.s, stores: []};
        bb.west = {x: bb.w, y: (bb.n + bb.s) / 2, stores: []};
        bb.northEast = {x: bb.e, y: bb.n, stores: []};
        bb.southEast = {x: bb.e, y: bb.s, stores: []};
        bb.southWest = {x: bb.w, y: bb.s, stores: []};
        bb.northWest = {x: bb.w, y: bb.n, stores: []};
        
		return bb;

	}


}

export default TerritoriesClass;