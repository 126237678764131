import React, { Component } from 'react';
import {Chart} from '../components/Chart';
import Service from '../components/Service';
import DateSelector from '../components/DateSelector';
import Selector from '../components/Selector';
import Popup from "reactjs-popup";
import {Data} from '../components/Data';
import Dataset from '../components/Dataset';

import HighchartsReact from "highcharts-react-official";
import highcharts from 'highcharts'
import  Select  from 'react-select';


class Sales extends Component {

    constructor(props){
        super(props);
        this.state = {
            dataSets: [],
            newDatasetName: '',
            createDataset: false
        }
    }
    addDataSet=()=>{
        this.setState({createDataset: true});
    }
    handleDatasetName=(event)=>{
        this.setState({newDatasetName: event.target.value});
    }
    submitDataset=()=>{
        let ds = this.state.dataSets;
        ds.push(this.state.newDatasetName)
        this.setState({dataSets:  ds, createDataset: false});
    }
    
    componentDidMount(){
        this.setState({dataSet: Data.getDataSet()});
    }
render() {
    return (
        <div className="content">
            <h1>Sales</h1>
            <button className="keyword" onClick={this.addDataSet}>Add Dataset +</button>
            {this.state.createDataset === true &&
                <div> 
                    <h3>Type dataset name:</h3>
                    <input type="text" onChange={this.handleDatasetName} value={this.state.newDatasetName}/>
                    <button className="keyword" onClick={this.submitDataset}>Submit</button>
                </div>
            }
        
            {/* <Dataset name="Tijana"/> */}
            {this.state.dataSets.map((item, index)=>{
                return(
                    <Dataset name={item}/>
                )
            })
            
            }
        </div>
      
    )
  }
}

export default Sales;