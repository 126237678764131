import React, { Component } from 'react';
import Service from '../components/Service';

const items = {
    data: []
};
class ImportCustom extends Component {
constructor() {
    super();
    this.state = {
      name: 'React',
      partners: [],
      page: 0,
      perPage: 20,
      route: '',
      auto: false,
      autoStopPage: 100
    };
  }
    sendData=()=>{
        let d = [];
        let page = parseInt(this.state.page);
        let perPage = parseInt(this.state.perPage);
        console.log((page * perPage), (page * perPage) - 1 + perPage);
        for(var i = (page * perPage); i <= (page * perPage) - 1 + perPage; i++){
            d.push(items.data[i].split('|'));
        }
        console.log('items to send:', d);
        Service.get('/import/'+this.state.route,{import: d},(res)=>{
          if(res.success){
            this.setState({page: parseInt(this.state.page) + 1},()=>{
              if(this.state.page <= this.state.autoStopPage){
                  if(this.state.auto === true){
                    this.sendData();
                  }
              }
              
            }
            )
          }
        });
    }

  handlePage=(event)=>{
      this.setState({page: event.target.value});
  }
  handleRoute=(event)=>{
      this.setState({route: event.target.value});
  }

  handlePerPage=(event)=>{
      this.setState({perPage: event.target.value});
  }

  showFile = () => {
    if (window.File && window.FileReader && window.FileList && window.Blob) {
         var preview = document.getElementById('show-text');
         var file = document.querySelector('input[type=file]').files[0];
         var reader = new FileReader()

         var textFile = /text.*/;

        //  var state = this.state;

         if (file.type.match(textFile)) {
             let data = [];
            reader.onload = function (event) {

                items.data = event.target.result.split(/\r?\n/);
                console.log(items.data);
               //preview.innerHTML = items.data.slice(1,10);//event.target.result.slice(0,50000);
               //data = partners;
            //    state.setState({partners: items},()=>{
            //        console.log(state.partners);
            //    });
               
               //console.log(event.target.result.slice(0,500000).split(/\r?\n/));
               //Service.get('/test/parseSellin',{sellin: event.target.result.slice(0,50000)});
            }
         } else {
            preview.innerHTML = "<span class='error'>It doesn't seem to be a text file!</span>";
         }
         reader.readAsText(file);

   } else {
      alert("Your browser is too old to support HTML5 File API");
   }
  }

    switchAuto=()=>{
        this.setState({auto: !this.state.auto});
    }
    
    handleAutoStop=(event)=>{
        this.setState({autoStopPage: event.target.value});
    }

  render() {
    return (
      <div>
        <h2>IMPORT CUSTOM ROUTE</h2>
        <input type="file" onChange={this.showFile} />
        
        <br />
        PAGE: <input type="text" value={this.state.page} onChange={this.handlePage} />
        PER PAGE: <input type="text" value={this.state.perPage} onChange={this.handlePerPage} />
        <br />
        ROUTE: <input type="text" value={this.state.route} onChange={this.handleRoute} />
        <br />
        PAGE STOP: <input type="text" value={this.state.autoStopPage} onChange={this.handleAutoStop} /><br />
        {this.state.auto == false &&
            <button onClick={this.switchAuto} className='keyword' style={{background: 'gray'}}>AUTO</button>
        }
        {this.state.auto == true &&
            <button onClick={this.switchAuto} className='keyword' style={{background: 'red'}}>AUTO</button>
        }
        <button onClick={this.sendData}>Posalji</button>
        <div id="show-text">Choose text File</div>
        {/* <button onClick={this.state.showFile}>Pregledaj</button> */}
        
        
        
      </div>
    );
  }

}

export default ImportCustom;