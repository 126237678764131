import React, { Component, Fragment } from 'react';
//import { useHistory } from "react-router-dom";
import { BrowserRouter as Router, Route, Link, Redirect} from "react-router-dom";
import {Chart} from '../components/Chart';
import Service from '../components/Service';
import OpenMap from '../components/OpenMap2';
import Territories from '../components/Territories';
import TerritoriesClass from '../components/TerritoriesClass';
import { CSVLink, CSVDownload } from "react-csv";
import ReactExport from "react-export-excel";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsMap from "highcharts/modules/map";
import proj4 from "proj4";
import mapDataIE from "@highcharts/map-collection/countries/rs/rs-all.geo.json";
import { geoCentroid } from "d3-geo";

import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  Annotation
} from "react-simple-maps";
import geoUrl from "../map-new-2-topo.json";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
}

const offsets = {
    'S2A3': {x: -6, y: 5},
    'S1A1': {x: -2, y: 10},
    'S1A4': {x: 10, y: 5},
}

function getOffset(name){
    if(offsets[name]){
        return offsets[name];
    }
    return {x: 0, y: 0}
}

//const history = useHistory();
// gjson = {
//     type: "Feature",
//     properties: {
//         name: item.name,
//         density: 94.65
//     },
//     geometry: {
//         type: "Polygon",
//         coordinates: [item.polygon]
//     } 
// }
// polys.push(gjson);



var centers = {
    "S1A1": [20.5029521,44.6711569,0],
        "S1A2": [21.3109615,44.857954,0],
        "S1A3": [20.6475673,44.874,0],
        "S1A4": [20.5324028,44.7767137,0],
        "S1A5": [21.5522893,44.3389263,0],
        "S1A6": [20.9005698,44.3176349,0],
        "S2A1": [20.1796269,45.1105333,0],
        "S2A2": [20.4216301,44.8092254,0],
        "S2A3": [20.3246062,44.6639832,0],
        "S2A4": [20.4451564,44.3167153,0],
        "S2A5": [19.189752,44.9561972,0],
        "S2A6": [19.1359657,44.5106644,0],
        "S3A1": [19.8931066,45.415745,0],
        "S3A2": [20.7805648,45.3699985,0],
        "S3A3": [19.0507057,45.3500716,0],
        "S3A4": [19.0326707,45.8917648,0],
        "S3A5": [20.3570812,45.9456724,0],
        "S3A6": [19.9420568,45.3022985,0],
        "S4A1": [19.6212107,44.1563113,0],
        "S4A2": [21.3024494,43.9580146,0],
        "S4A3": [20.8566244,43.7204066,0],
        "S4A4": [20.1578892,43.7408747,0],
        "S4A5": [19.2797062,43.4517555,0],
        "S4A6": [21.0132785,43.3864473,0],
        "S4A7": [20.4438211,43.0259089,0],
        "S5A1": [21.8002691,42.812002,0],
        "S5A2": [21.9092583,43.4324001,0],
        "S5A3": [22.819412,43.007697,0],
        "S5A4": [22.50494,44.2271529,0],
        "S5A5": [21.3229912,43.4032494,0],
        "S5A6": [22.21637,42.3818429,0]
    
}

var mapData = [{
        type: "Feature",
        properties: {
            shape: "Polygon",
            name: "S3A4",
            category: "default"
        },
        geometry: {
            type: "Polygon",
            coordinates: [
                [
                    [19.805455, 46.13417],
                    [19.618687, 46.183634],
                    [18.833165, 45.897655],
                    [18.915562, 45.598666],
                    [19.767003, 45.740693],
                    [19.673619, 45.9053],
                    [19.805455, 46.13417]
                ]
            ]
        },
        id: "c1301ad4-a6e7-48ee-a969-e9385cfe6d8f"
    }, {
        type: "Feature",
        properties: {
            shape: "Polygon",
            name: "S3A5",
            category: "default"
        },
        geometry: {
            type: "Polygon",
            coordinates: [
                [
                    [19.805455, 46.13417],
                    [19.673619, 45.9053],
                    [19.767003, 45.740693],
                    [20.033273, 45.744527],
                    [20.209054, 45.514046],
                    [20.857248, 45.452424],
                    [20.813302, 45.752193],
                    [20.296945, 46.111326],
                    [20.055246, 46.187437],
                    [19.805455, 46.13417]
                ]
            ]
        },
        id: "33e24e16-8c8b-4bbc-9125-495747636e9e"
    }, {
        type: "Feature",
        properties: {
            shape: "Polygon",
            name: "S3A3",
            category: "default"
        },
        geometry: {
            type: "Polygon",
            coordinates: [
                [
                    [18.915562, 45.598666],
                    [19.011544, 45.352145],
                    [19.429025, 45.18978],
                    [19.857492, 45.274886],
                    [19.604806, 45.467836],
                    [19.767003, 45.740693],
                    [18.915562, 45.598666]
                ]
            ]
        },
        id: "360389ec-a60f-4e18-82c8-5e87eb356de2"
    }, {
        type: "Feature",
        properties: {
            shape: "Polygon",
            name: "S3A1",
            category: "default"
        },
        geometry: {
            type: "Polygon",
            coordinates: [
                [
                    [19.857492, 45.274886],
                    [19.956369, 45.467836],
                    [20.209054, 45.514046],
                    [20.033273, 45.744527],
                    [19.767003, 45.740693],
                    [19.604806, 45.467836],
                    [19.857492, 45.274886]
                ]
            ]
        },
        id: "28a7bf90-c875-4094-9591-903c14e2e85b"
    }, {
        type: "Feature",
        properties: {
            shape: "Polygon",
            name: "S3A6",
            category: "default"
        },
        geometry: {
            type: "Polygon",
            coordinates: [
                [
                    [19.956369, 45.467836],
                    [19.857492, 45.274886],
                    [19.429025, 45.18978],
                    [19.449847, 45.149116],
                    [20.168004, 45.18978],
                    [20.294346, 45.127805],
                    [20.38935, 45.496913],
                    [20.209054, 45.514046],
                    [19.956369, 45.467836]
                ]
            ]
        },
        id: "4ffdbaf6-e8fe-4b0f-85ac-5ab56e83c2fc"
    }, {
        type: "Feature",
        properties: {
            shape: "Polygon",
            name: "S3A2",
            category: "default"
        },
        geometry: {
            type: "Polygon",
            coordinates: [
                [
                    [20.294346, 45.127805],
                    [20.371251, 45.015302],
                    [20.959019, 45.019185],
                    [21.469884, 45.069641],
                    [21.519322, 45.193651],
                    [20.857248, 45.452424],
                    [20.38935, 45.496913],
                    [20.294346, 45.127805]
                ]
            ]
        },
        id: "51699864-afb2-41e2-ac26-14efe4a4c585"
    }, {
        type: "Feature",
        properties: {
            shape: "Polygon",
            name: "S2A2",
            category: "default"
        },
        geometry: {
            type: "Polygon",
            coordinates: [
                [
                    [20.437169, 44.824708],
                    [19.767003, 44.902578],
                    [19.712071, 44.750634],
                    [19.992222, 44.660839],
                    [20.288853, 44.71161],
                    [20.437169, 44.824708]
                ]
            ]
        },
        id: "b0282f2e-d35c-4494-9630-277f6376aa06"
    }, {
        type: "Feature",
        properties: {
            shape: "Polygon",
            name: "S2A3",
            category: "default"
        },
        geometry: {
            type: "Polygon",
            coordinates: [
                [
                    [19.992222, 44.660839],
                    [20.157017, 44.418088],
                    [20.393223, 44.418088],
                    [20.320513, 44.582643],
                    [20.437169, 44.824708],
                    [20.288853, 44.71161],
                    [19.992222, 44.660839]
                ]
            ]
        },
        id: "2764b0d8-e870-4635-9db5-d339be16ee42"
    }, {
        type: "Feature",
        properties: {
            shape: "Polygon",
            name: "S2A1",
            category: "default"
        },
        geometry: {
            type: "Polygon",
            coordinates: [
                [
                    [20.437169, 44.824708],
                    [20.266881, 44.964798],
                    [20.371251, 45.015302],
                    [20.294346, 45.127805],
                    [20.168004, 45.18978],
                    [19.798375, 45.168854],
                    [19.767003, 45.038597],
                    [20.012198, 44.8741],
                    [20.437169, 44.824708]
                ]
            ]
        },
        id: "5b5473f2-462d-4a38-9007-86110f9ff38f"
    }, {
        type: "Feature",
        properties: {
            shape: "Polygon",
            name: "S2A5",
            category: "default"
        },
        geometry: {
            type: "Polygon",
            coordinates: [
                [
                    [20.012198, 44.8741],
                    [19.767003, 45.038597],
                    [19.798375, 45.168854],
                    [19.449847, 45.149116],
                    [19.223179, 45.178165],
                    [19.08585, 45.143305],
                    [19.069371, 44.980342],
                    [19.151768, 44.953137],
                    [19.014439, 44.910359],
                    [18.99796, 44.855869],
                    [19.344029, 44.902578],
                    [19.767003, 44.902578],
                    [20.012198, 44.8741]
                ]
            ]
        },
        id: "d2e98c82-4261-4690-b3f6-09fb07d94cbd"
    }, {
        type: "Feature",
        properties: {
            shape: "Polygon",
            name: "S2A6",
            category: "default"
        },
        geometry: {
            type: "Polygon",
            coordinates: [
                [
                    [19.344029, 44.902578],
                    [19.355015, 44.731126],
                    [19.113316, 44.508259],
                    [19.124303, 44.37884],
                    [19.179234, 44.280604],
                    [19.322056, 44.264871],
                    [19.41544, 44.162504],
                    [19.486851, 44.319918],
                    [19.459386, 44.472991],
                    [19.580235, 44.735028],
                    [19.712071, 44.750634],
                    [19.767003, 44.902578],
                    [19.344029, 44.902578]
                ]
            ]
        },
        id: "321bf083-e485-41d5-9050-65841e26639a"
    }, {
        type: "Feature",
        properties: {
            shape: "Polygon",
            name: "S2A4",
            category: "default"
        },
        geometry: {
            type: "Polygon",
            coordinates: [
                [
                    [20.157017, 44.418088],
                    [20.277867, 44.186143],
                    [20.40421, 43.98491],
                    [20.601964, 44.067854],
                    [20.794224, 44.091531],
                    [20.700841, 44.414164],
                    [20.486607, 44.355278],
                    [20.393223, 44.418088],
                    [20.157017, 44.418088]
                ]
            ]
        },
        id: "631bcc1a-2b92-40bd-9896-58f85ad6b4f0"
    }, {
        type: "Feature",
        properties: {
            shape: "Polygon",
            name: "S1A1",
            category: "default"
        },
        geometry: {
            type: "Polygon",
            coordinates: [
                [
                    [20.700841, 44.414164],
                    [20.437169, 44.824708],
                    [20.320513, 44.582643],
                    [20.393223, 44.418088],
                    [20.486607, 44.355278],
                    [20.700841, 44.414164]
                ]
            ]
        },
        id: "70505622-7a6a-4012-9c45-8200222dbcf1"
    }, {
        type: "Feature",
        properties: {
            shape: "Polygon",
            name: "S1A4",
            category: "default"
        },
        geometry: {
            type: "Polygon",
            coordinates: [
                [
                    [20.700841, 44.414164],
                    [20.964512, 44.437702],
                    [20.920567, 44.664746],
                    [20.788731, 44.653024],
                    [20.640416, 44.746733],
                    [20.634923, 44.840291],
                    [20.437169, 44.824708],
                    [20.700841, 44.414164]
                ]
            ]
        },
        id: "940cfec9-0b91-4278-beda-0f1ed2364cf1"
    }, {
        type: "Feature",
        properties: {
            shape: "Polygon",
            name: "S1A3",
            category: "default"
        },
        geometry: {
            type: "Polygon",
            coordinates: [
                [
                    [20.943693, 44.545374],
                    [21.118321, 44.566991],
                    [21.096348, 44.719417],
                    [20.959019, 45.019185],
                    [20.371251, 45.015302],
                    [20.266881, 44.964798],
                    [20.437169, 44.824708],
                    [20.634923, 44.840291],
                    [20.640416, 44.746733],
                    [20.788731, 44.653024],
                    [20.920567, 44.664746],
                    [20.943693, 44.545374]
                ]
            ]
        },
        id: "a6596fb6-5311-4c50-bb32-d09524005dce"
    }, {
        type: "Feature",
        properties: {
            shape: "Polygon",
            name: "S1A2",
            category: "default"
        },
        geometry: {
            type: "Polygon",
            coordinates: [
                [
                    [21.469884, 45.069641],
                    [20.959019, 45.019185],
                    [21.096348, 44.719417],
                    [21.118321, 44.566991],
                    [21.27213, 44.625664],
                    [21.486363, 44.508259],
                    [21.826939, 44.496505],
                    [21.931309, 44.637391],
                    [21.634678, 44.668653],
                    [21.590733, 44.766237],
                    [21.392979, 44.777936],
                    [21.365513, 44.859763],
                    [21.568761, 44.887012],
                    [21.552281, 44.937585],
                    [21.414952, 44.957024],
                    [21.380694, 45.015302],
                    [21.469884, 45.069641]
                ]
            ]
        },
        id: "ae1a877b-31bd-4b14-8889-8d50f0fba245"
    }, {
        type: "Feature",
        properties: {
            shape: "Polygon",
            name: "S1A6",
            category: "default"
        },
        geometry: {
            type: "Polygon",
            coordinates: [
                [
                    [20.700841, 44.414164],
                    [20.794224, 44.091531],
                    [21.145787, 43.977005],
                    [21.283116, 44.111254],
                    [21.244664, 44.43378],
                    [21.27213, 44.625664],
                    [21.118321, 44.566991],
                    [20.943693, 44.545374],
                    [20.964512, 44.437702],
                    [20.700841, 44.414164]
                ]
            ]
        },
        id: "6bd2d46c-b8c4-40b6-8f86-7436915c9aec"
    }, {
        type: "Feature",
        properties: {
            shape: "Polygon",
            name: "S1A5",
            category: "default"
        },
        geometry: {
            type: "Polygon",
            coordinates: [
                [
                    [21.145787, 43.977005],
                    [21.305089, 43.77506],
                    [21.590733, 43.826601],
                    [21.469884, 43.992815],
                    [21.878976, 44.205835],
                    [21.826939, 44.496505],
                    [21.486363, 44.508259],
                    [21.27213, 44.625664],
                    [21.244664, 44.43378],
                    [21.283116, 44.111254],
                    [21.145787, 43.977005]
                ]
            ]
        },
        id: "bc011e2f-73f2-453c-8253-9741001644fe"
    }, {
        type: "Feature",
        properties: {
            shape: "Polygon",
            name: "S4A1",
            category: "default"
        },
        geometry: {
            type: "Polygon",
            coordinates: [
                [
                    [19.41544, 44.162504],
                    [19.648751, 44.024422],
                    [19.560861, 43.969098],
                    [19.967355, 43.92955],
                    [20.277867, 44.186143],
                    [20.157017, 44.418088],
                    [19.992222, 44.660839],
                    [19.712071, 44.750634],
                    [19.580235, 44.735028],
                    [19.459386, 44.472991],
                    [19.486851, 44.319918],
                    [19.41544, 44.162504]
                ]
            ]
        },
        id: "4bbe064b-9eae-4c62-ab5a-4184982b5888"
    }, {
        type: "Feature",
        properties: {
            shape: "Polygon",
            name: "S4A5",
            category: "default"
        },
        geometry: {
            type: "Polygon",
            coordinates: [
                [
                    [19.560861, 43.969098],
                    [19.231271, 44.016521],
                    [19.549875, 43.620171],
                    [19.440011, 43.564472],
                    [19.253244, 43.588349],
                    [19.253244, 43.47684],
                    [19.560861, 43.301196],
                    [19.703683, 43.165123],
                    [19.956369, 43.084937],
                    [20.121164, 43.253205],
                    [20.099191, 43.421009],
                    [19.945382, 43.667872],
                    [19.967355, 43.92955],
                    [19.560861, 43.969098]
                ]
            ]
        },
        id: "53923204-c990-4d89-b35a-cf9fcb58edaa"
    }, {
        type: "Feature",
        properties: {
            shape: "Polygon",
            name: "S4A4",
            category: "default"
        },
        geometry: {
            type: "Polygon",
            coordinates: [
                [
                    [20.277867, 44.186143],
                    [19.967355, 43.92955],
                    [19.945382, 43.667872],
                    [20.099191, 43.421009],
                    [20.38773, 43.604262],
                    [20.618443, 43.612217],
                    [20.667882, 43.759193],
                    [20.354771, 43.794889],
                    [20.40421, 43.98491],
                    [20.277867, 44.186143]
                ]
            ]
        },
        id: "e32fe773-7229-427e-8296-e6d6bda7ad3c"
    }, {
        type: "Feature",
        properties: {
            shape: "Polygon",
            name: "S4A3",
            category: "default"
        },
        geometry: {
            type: "Polygon",
            coordinates: [
                [
                    [20.667882, 43.759193],
                    [20.931659, 44.046786],
                    [20.794224, 44.091531],
                    [20.601964, 44.067854],
                    [20.40421, 43.98491],
                    [20.354771, 43.794889],
                    [20.667882, 43.759193]
                ]
            ]
        },
        id: "69a93335-315a-463c-be67-15827d4c89ef"
    }, {
        type: "Feature",
        properties: {
            shape: "Polygon",
            name: "S4A7",
            category: "default"
        },
        geometry: {
            type: "Polygon",
            coordinates: [
                [
                    [19.956369, 43.084937],
                    [20.140538, 42.936318],
                    [20.343785, 42.90816],
                    [20.40421, 42.82361],
                    [20.547032, 42.863886],
                    [20.689854, 43.104994],
                    [20.607457, 43.201172],
                    [20.618443, 43.612217],
                    [20.38773, 43.604262],
                    [20.099191, 43.421009],
                    [20.121164, 43.253205],
                    [19.956369, 43.084937]
                ]
            ]
        },
        id: "15d63fc7-7836-4cb9-84d7-0756ad61e45d"
    }, {
        type: "Feature",
        properties: {
            shape: "Polygon",
            name: "S4A6",
            category: "default"
        },
        geometry: {
            type: "Polygon",
            coordinates: [
                [
                    [20.607457, 43.201172],
                    [20.832677, 43.257206],
                    [20.876622, 43.157109],
                    [21.134801, 43.109004],
                    [21.173253, 42.992595],
                    [21.244664, 43.016697],
                    [21.25565, 42.90816],
                    [21.392979, 42.892064],
                    [21.469884, 43.181147],
                    [21.343541, 43.452919],
                    [20.618443, 43.612217],
                    [20.607457, 43.201172]
                ]
            ]
        },
        id: "13f87d9c-b522-47aa-aac3-f85066aed8ff"
    }, {
        type: "Feature",
        properties: {
            shape: "Polygon",
            name: "S4A2",
            category: "default"
        },
        geometry: {
            type: "Polygon",
            coordinates: [
                [
                    [21.343541, 43.452919],
                    [21.305089, 43.77506],
                    [21.145787, 43.977005],
                    [20.931659, 44.046786],
                    [20.667882, 43.759193],
                    [20.618443, 43.612217],
                    [21.343541, 43.452919]
                ]
            ]
        },
        id: "cdbca9cf-ab2e-439a-a5a6-8e62cd0e5a9b"
    }, {
        type: "Feature",
        properties: {
            shape: "Polygon",
            name: "S5A5",
            category: "default"
        },
        geometry: {
            type: "Polygon",
            coordinates: [
                [
                    [21.392979, 42.892064],
                    [22.054757, 43.004647],
                    [21.670236, 43.34116],
                    [21.692209, 43.604262],
                    [21.590733, 43.826601],
                    [21.305089, 43.77506],
                    [21.343541, 43.452919],
                    [21.469884, 43.181147],
                    [21.392979, 42.892064]
                ]
            ]
        },
        id: "65376366-0040-4318-bed6-f8202f0d6f39"
    }, {
        type: "Feature",
        properties: {
            shape: "Polygon",
            name: "S5A1",
            category: "default"
        },
        geometry: {
            type: "Polygon",
            coordinates: [
                [
                    [21.392979, 42.892064],
                    [21.414952, 42.747012],
                    [21.667637, 42.67032],
                    [21.79398, 42.722804],
                    [21.81046, 42.621834],
                    [21.964268, 42.549034],
                    [22.151036, 42.863886],
                    [22.054757, 43.004647],
                    [21.392979, 42.892064]
                ]
            ]
        },
        id: "f56fd752-8101-45f5-aa62-2e0585a68c0e"
    }, {
        type: "Feature",
        properties: {
            shape: "Polygon",
            name: "S5A6",
            category: "default"
        },
        geometry: {
            type: "Polygon",
            coordinates: [
                [
                    [21.81046, 42.621834],
                    [21.623692, 42.427511],
                    [21.618199, 42.358544],
                    [21.519322, 42.338245],
                    [21.601719, 42.236652],
                    [21.722569, 42.236652],
                    [21.859898, 42.313878],
                    [21.986241, 42.338245],
                    [22.046666, 42.293564],
                    [22.304844, 42.362603],
                    [22.381749, 42.313878],
                    [22.535557, 42.395066],
                    [22.57401, 42.500453],
                    [22.458653, 42.561173],
                    [22.491612, 42.682435],
                    [22.040523, 42.677777],
                    [21.964268, 42.549034],
                    [21.81046, 42.621834]
                ]
            ]
        },
        id: "24de0edf-a59c-42d2-acd9-9ee61300a2c9"
    }, {
        type: "Feature",
        properties: {
            shape: "Polygon",
            name: "S5A2",
            category: "default"
        },
        geometry: {
            type: "Polygon",
            coordinates: [
                [
                    [21.854227, 43.180372],
                    [21.909337, 43.313188],
                    [22.156529, 43.357138],
                    [22.205968, 43.504737],
                    [22.054757, 43.850374],
                    [21.590733, 43.826601],
                    [21.692209, 43.604262],
                    [21.670236, 43.34116],
                    [21.854227, 43.180372]
                ]
            ]
        },
        id: "687736aa-6b3e-4486-851d-d6e384815ec1"
    }, {
        type: "Feature",
        properties: {
            shape: "Polygon",
            name: "S5A3",
            category: "default"
        },
        geometry: {
            type: "Polygon",
            coordinates: [
                [
                    [22.491612, 42.682435],
                    [22.504769, 42.739952],
                    [22.442174, 42.811522],
                    [22.541051, 42.871938],
                    [22.67838, 42.863886],
                    [22.76627, 42.87999],
                    [22.771763, 42.956423],
                    [22.88712, 43.008664],
                    [22.909093, 43.076913],
                    [23.007969, 43.113014],
                    [23.013463, 43.189158],
                    [22.898106, 43.217187],
                    [22.837681, 43.329174],
                    [22.749791, 43.373112],
                    [22.524571, 43.496768],
                    [22.205968, 43.504737],
                    [22.156529, 43.357138],
                    [21.909337, 43.313188],
                    [21.854227, 43.180372],
                    [22.054757, 43.004647],
                    [22.151036, 42.863886],
                    [22.040523, 42.677777],
                    [22.491612, 42.682435]
                ]
            ]
        },
        id: "c3e53901-1093-4847-be05-e8ae0dfbfd62"
    }, {
        type: "Feature",
        properties: {
            shape: "Polygon",
            name: "S5A4",
            category: "default"
        },
        geometry: {
            type: "Polygon",
            coordinates: [
                [
                    [22.524571, 43.496768],
                    [22.497105, 43.632099],
                    [22.354283, 43.806783],
                    [22.425694, 43.992815],
                    [22.628941, 44.044167],
                    [22.606969, 44.123085],
                    [22.689366, 44.205835],
                    [22.689366, 44.272738],
                    [22.57401, 44.300264],
                    [22.480626, 44.449468],
                    [22.590489, 44.531759],
                    [22.689366, 44.508259],
                    [22.788243, 44.531759],
                    [22.700352, 44.606113],
                    [22.462847, 44.705754],
                    [22.309038, 44.654978],
                    [22.177203, 44.471031],
                    [22.081072, 44.500423],
                    [22.009661, 44.631528],
                    [21.931309, 44.637391],
                    [21.826939, 44.496505],
                    [21.878976, 44.205835],
                    [21.469884, 43.992815],
                    [21.590733, 43.826601],
                    [22.054757, 43.850374],
                    [22.205968, 43.504737],
                    [22.524571, 43.496768]
                ]
            ]
        },
        id: "7aa1a885-b747-438f-8607-fc537ccdc023"
    }]



highchartsMap(Highcharts);

if (typeof window !== "undefined") {
  window.proj4 = window.proj4 || proj4;
}



const mapOptions = {
    chart: {
      map: 'countries/ie/ie-all',
      height: '800px'
    },
    xAxis: {
            minRange: 1
    },
    yAxis: {
        minRange: 1
    },
    title: {
      text: 'Doncafe Stores'
    },
    credits: {
        enabled: false
    },
    mapNavigation: {
      enabled: true
    },
    tooltip: {
      headerFormat: '',
      pointFormat: '<b>{point.freq}</b><br><b>{point.keyword}</b>                      <br>lat: {point.lat}, lon: {point.lon}'
    },
    series: [{
      // Use the gb-all map with no data as a basemap
      name: 'DoncafeMap',
      mapData: mapDataIE,
      borderColor: '#A0A0A0',
      nullColor: 'rgba(200, 200, 200, 0.3)',
      showInLegend: false
    }, {
      // Specify points using lat/lon
      type: 'mapbubble',
      name: 'Stores',
      color: '#b90606',
      minSize: '4px',
    maxSize: '5px',
      data: [{ z: 10, keyword: "Beograd", lat: 44.7866, lon: 20.4489 }, 
             { z: 4, keyword: "Niš", lat: 43.3209, lon: 21.8958 }],
      cursor: 'pointer',
      point: {
        events: {
          click: function() {
            ////console.log(this.keyword);
          }
        }
      }
    }]
  }

const geoData = [
    {
    type: "Feature",
    properties: {
        name: "Alabama",
        density: 94.65
    },
    geometry: {
               type: "Polygon",
               coordinates: [
                   [
                       [20.4786287000, 44.8004631800],
                       [20.5761070900, 44.3005814500],
                       [20.4497378100, 44.7048680100],
                       [20.4786287000, 44.8004631800]
                   ]
            ]
        } 
    }
]

function getTValue(t, stores){
    let value = 0;
    let count = 0;
    stores.map(function(item, index){
        if(item.territory === t){
            value += parseFloat(item.value);
            count ++;
        }
    },this)

    return {total: value, average: value / count};
}
class Map extends Component {

    constructor(props){
        super(props);
        this.state = {
            stores: [],
            mapOptions: null,
            selectedTerritory: 'all',
            territories: [],
            coordinates: [],
            storesOutside: '',
            routes: [],
            defMap: false,
            selectedDay: 'all',
            selectedWeek: 1,
            csvData: [],
            loadingCsv: false,
            excelData:[],
            excelRegionData:[],
            selectedStore: null,
            selectedRegion:'',
            regions:[],
            regionStores: [],
            selectedVersion: 3,
            lastFunction: null,
            polygon: null,
            section:null,
            territoryStoreCount: [0,0,0,0,0],
            polygons: [],
            action: 'getSubBrandsByTeritory',
            storesWithoutDoncafeHasCoffee: [],
            surveyActions:[],
            surveyActionsData:[],
            selectedAction:'',
            selectedActionFilters:[],
            isFilters: false,
            selectedFilters: {},
            description: '',
            loading: false,
            excelButtonVisible: false,
            totalScore: '',
            regionsScores: [],
            selectedFilterName: [],
            markerData:[],
            selectedPolygon: '',
            totalValue: '',
            switchCheckbox: true
        }

    }

    componentDidMount=()=>{
        //this.getData();
        this.getSurveyActions();

    }

    // MAIN SURVEY FUNCTION
   getPolygons=()=>{
        Service.get('/import/getPolygons',{},(res)=>{
            let polys = [];
            let gjson;
            if(res.success){
                res.data.polygons.map((item)=>{
                    gjson = {
                                    type: "Feature",
                                    properties: {
                                        name: item.name,
                                        density: 94.65
                                    },
                                    geometry: {
                                        type: "Polygon",
                                        coordinates: [item.polygon]
                                    } 
                                }
                    polys.push(gjson);
                })
                
                console.log('polys', polys);
                this.setState({polygons: polys},()=>{
                    console.log('pl', this.state.polygons);
                });
            }
        })
    }

    getData=()=>{
        this.setState({loading: true});
        console.log('action', this.props.match.params.action);
        let a = this.state.selectedAction.route;
        let f = this.state.selectedFilters;
        if(!f){
            f = ''
        }
        if(!a){
            a = 'getSubBrandsByTeritory';
        }
        Service.get('/analytics/survey', {action: a, filters: f}, (res)=>{
            this.setScore(res.data);
            let p = mapData;
            let s = [];
            let ed = [];

            res.data.map( itm => {
                if(itm.stores){
                    itm.stores.map( store => {
                        s.push({
                            lat: store.latitude, 
                            lng: store.longitude, 
                            territory: store.territory, 
                            class: 'leaflet-marker', 
                            label: store.store_doncafe_code
                        });
                        ed.push({
                            cooperation: store.cooperation,
                            hasCoffee: store.has_coffee,
                            latitude: store.latitude,
                            longitude: store.longitude,
                            storeDoncafeCode: store.store_doncafe_code,
                            territory: store.territory,
                            storeAddress: store.store_address,
                            storeName: store.store_name,
                            value: store.value,
                            name: store.name
                        })
                    })
                }
            });

            // this.state.storesWithoutDoncafeHasCoffee[name].stores.map(function(item, index){
        //     data.push({lat: item.latitude, lng: item.longitude, territory: item.territory, class: 'leaflet-marker', label: item.territory})
        // })
            
            p.map( item => {
                res.data.map( itm => {
                    if(item.properties.name == itm.territory){
                        item.value = itm.value;
                        item.lat = centers[itm.territory][0];
                        item.lng = centers[itm.territory][1];
                        item.score = itm.score;
                        item.scoreCount = itm.stores.length;
                        item.territory = itm.territory;
                    }
                })
            })
            if(p){
                p.sort((a, b) => (a.territory > b.territory) ? 1 : -1)
                this.setState({polygons: p, stores: s, loading: false}, ()=>{
                    let ls = [];
                    let limit = 500;
                    s.map((store, index) => {
                        //if(index < limit){
                            ls.push(store);
                        //}
                    })
                    
                    let a = shuffle(ls).slice(0, limit);
                    this.setState({routes: a});
                    this.exportExcelData(ed, this.state.polygons);
                    // console.log('stores', this.state.stores);
                });
                
            }
        })
    }

    setScore=(data)=>{    
        let regions = [
            {name: "S1", score: 0, color:"#fff3f2"},
            {name: "S2", score: 0, color:"#fed4d2"},
            {name: "S3", score: 0, color:"#ffada9"},
            {name: "S4", score: 0, color:"#ff9690"},
            {name: "S5", score: 0, color:"#ff291e"}
        ]

        regions.map(region=>{
            let sum = 0;

            data.map(territory=>{
               if(territory.territory && territory.territory.substring(0, 2) === region.name){
                   sum += parseFloat(territory.score);
               }
            })
            region.score = sum;
        })

        this.setState({regionsScores: regions},()=>{
            let totalSum = this.state.regionsScores.reduce(function(prev, current) {
                return prev + +current.score
            }, 0);
            this.setState({totalScore: totalSum})
        })

    }

    getSurveyActions=()=>{
        Service.get('/analytics/getSurveyActions',{},(res)=>{
            if(res.success){
                this.setState({surveyActions: res.data.actions},()=>{
                    this.setSurveyActions();
                })
            }
        });
    }

    setSurveyActions=()=>{
        let data=[];

        this.state.surveyActions.map(function(item, index){
            data.push({id: index, filters: item.filters, name: item.name, route: item.route, description: item.description})
        })

        this.setState({surveyActionsData: data},()=>{
        })
    }

    selectAction=(event)=>{
        if(event.target.value == "title"){
             return;
        }
        // console.log('aaaa', event.target.value);
        this.setState({action: event.target.value, selectedFilters: {}, selectedFilterName: []},()=>{
            // console.log('sa', this.state.surveyActionsData[parseInt(this.state.action)]);
            this.setState({selectedAction: this.state.surveyActionsData[parseInt(this.state.action)], description: this.state.surveyActionsData[parseInt(this.state.action)].description},()=>{
                if(this.state.selectedAction.filters && this.state.selectedAction.filters.length > 0){
                    this.setState({isFilters: true, selectedActionFilters: this.state.selectedAction.filters},()=>{
                    })
                }else{
                    this.setState({isFilters: false, selectedActionFilters: this.state.selectedAction.filters})
                }
            })
        });
    }

    selectFilter=(e, key)=>{
        let filterId = e.target.value;
        let filter = this.state.selectedFilters;
        filter[key] = filterId;
        

        this.setState({...this.state.selectedFilters, selectedFilters: filter},()=>{
            this.selectedFilterName(filterId);
        }) 
    }

    selectedFilterName=(filterId)=>{
        let filterName = [];

        this.state.selectedActionFilters.map(function(item, index){
            item.options.map(function(option, key){
                if(option.value === filterId){
                    filterName.push(option.name);
                }
            },this)   
        },this)

        this.setState(previousState => ({
            selectedFilterName: [...previousState.selectedFilterName, filterName]
        }));

    }

    getStoresBySelectedRegion=(name)=>{
        console.log('name', name);
        let s = [];
        this.state.stores.map(store=>{
            if(store.territory == name){
                s.push(store);
            }
        })
        this.setState({routes: s, selectedRegion: name});

    }

    displayStores=()=>{

    }

    getMarkerData=(event)=>{
        let s = [];

        this.state.excelData.map(store=>{
            if(store.storeDoncafeCode === event){
                s.push(store);
            }
        })

        this.setState({markerData: s});
    }


    exportExcelData=(stores, polygons)=>{
        let excelData = [];
        let current = '';
        let currentScore = 0;
        let totalValues = [];
        let totalAverages = [];
       
            stores.map(function(item, index){
                    let value = 0;
                    polygons.map(function(polygon, key){
                        value += parseFloat(item.value);
                        if(item.territory != current){
                            if(currentScore > 0 || getTValue(current, stores)["total"] > 0 || getTValue(current, stores)["average"] > 0){
                                excelData.push({territory: "total", score: currentScore, cooperation: "", hasCoffee: "", latitude: "", longitude: "", storeDoncafeCode: "",
                                storeAddress: "", storeName: "total", value: getTValue(current, stores)["total"], name: "average", average: getTValue(current, stores)["average"] 
                                })
                                excelData.push({territory: "", score: "", cooperation: "", hasCoffee: "", latitude: "", longitude: "", storeDoncafeCode: "",
                                storeAddress: "", storeName: "", value: "", name: ""
                                })
                            }
                             current = item.territory;
                             currentScore = 0;
                             totalValues.push(getTValue(current, stores)["total"]);
                             totalAverages.push(getTValue(current, stores)["average"]);
                        }
                        if(item.territory === polygon.territory){
                            excelData.push({territory: item.territory, score: polygon.score, cooperation: item.cooperation, hasCoffee: item.hasCoffee, latitude: item.latitude, longitude: item.longitude, storeDoncafeCode: item.storeDoncafeCode,
                                            storeAddress: item.storeAddress, storeName: item.storeName, value: item.value, name: item.name
                            })
                            currentScore = polygon.score;
                        }
                    },this)
            },this)

            this.state.regionsScores.map(score=>{
                excelData.push({territory: score.name, score: score.score, cooperation: "", hasCoffee: "", latitude: "", longitude: "", storeDoncafeCode: "",
                storeAddress: "", storeName: "", value: "", name: ""})
            })

            let totalValue = totalValues.reduce((a, b) => a + b, 0);
            let totalAverage = totalAverages.reduce((a, b) => a + b, 0);
            excelData.push({territory: "total score", score: this.state.totalScore, cooperation: "", hasCoffee: "", latitude: "", longitude: "", storeDoncafeCode: "",
            storeAddress: "", storeName: "total value", value: totalValue, name: "total average", average: totalAverage})


            this.setState({excelData: excelData, excelButtonVisible: true},()=>{   
        });

    }

    setSurveyPolygon=()=>{
        if(this.state.switchCheckbox === false){
            this.setState({selectedPolygon: '', switchCheckbox: true})

        }else if(this.state.switchCheckbox === true){
            this.setState({selectedPolygon: "1", switchCheckbox: false})
        }
    }

  render() {
    let excelFileName = '';
    if(this.state.selectedFilterName.length > 0){
        excelFileName = "doncafe-"+this.state.selectedAction.route+""+this.state.selectedFilterName.map(function(item, key){
            return "-"+ item;
        })
    }else{
        excelFileName = "doncafe-"+this.state.selectedAction.route;
    }
    return (
      <div className="content">
          <h1>Survey</h1>
          <label htmlFor="survey-actions">Actions:</label>
          <select  value={this.state.action} onChange={this.selectAction} id="survey-actions">
                <option selected value="title">Select Action:</option>
                {this.state.surveyActionsData.map(function(item, index){
                    return(
                        <option value={item.id} key={index}>{item.name}</option>
                    )},this
                )};
          </select>

        {this.state.isFilters === true &&    
            <Fragment>
                {this.state.selectedActionFilters.map(function(item, index){
                    return(
                        <Fragment>
                            <label htmlFor={"survey-"+item.name}>{item.name}:</label>
                            <select  value={this.state.filter} onChange={(e)=>this.selectFilter(e, item.value)} id={"survey-"+item.name}>
                                <option value="null">All</option>
                                {item.options.map(function(option, key){
                                    if(option.name){
                                        return(
                                            <option value={option.value} key={key}>{option.name}</option>
                                        )
                                    }
                                    },this
                                )};
                            </select>
                        </Fragment>
                    )},this
                )}
            </Fragment>     
        }

          <button className="keyword" onClick={this.getData}>Get Data</button>

          {/* <Link to={'/survey/getSubBrandsByTeritory'}>Get Sub-brands</Link>
          <Link to={'/survey/getSubBrandsByProducer'}>Get producer</Link>
          <Link to={'/survey/getSubBrandsBySale'}>Get by sale</Link> */}
            
            {this.state.loading === true &&
                <span >
                    Loading. Please wait...
                </span>
            }

        {(this.state.loading === false && this.state.excelButtonVisible === true) &&
            <ExcelFile element={<button className="keyword">Export excel</button>} filename={excelFileName}>
                <ExcelSheet data={this.state.excelData} name={excelFileName+".csv"}>
                    <ExcelColumn label="territory" value="territory" />
                    <ExcelColumn label="score" value="score" />
                    <ExcelColumn label="cooperation" value="cooperation" />
                    <ExcelColumn label="hasCoffee" value="hasCoffee" />
                    <ExcelColumn label="latitude" value="latitude" />
                    <ExcelColumn label="longitude" value="longitude" />
                    <ExcelColumn label="storeDoncafeCode" value="storeDoncafeCode" />
                    <ExcelColumn label="storeAddress" value="storeAddress" />
                    <ExcelColumn label="storeName" value="storeName" />
                    <ExcelColumn label="value" value="value" />
                    <ExcelColumn label="name" value="name" />
                    <ExcelColumn label="" value="average" />
                </ExcelSheet>
            </ExcelFile>
        }
        
            
        <div className="survey-maps">
            <div id="survey-simple-map">

                <div className="switch-box">
                    <span className="switch-option">Regions</span>
                    <label class="survey-switch">
                        <input type="checkbox" checked={this.state.switchCheckbox} onClick={this.setSurveyPolygon}/>
                        <span class="survey-slider"></span>
                    </label>
                    <span className="switch-option">Territories</span>
                </div>

                <ComposableMap 
                        projection="geoAzimuthalEqualArea"
                        projectionConfig={{
                        rotate: [-20.0, -44.0, 0],
                        scale: 8000
                    }}
                >
                <Geographies geography={geoUrl}>
                    {({ geographies }) => (
                    <>
                        {geographies.map(geo => {
                            const cur = this.state.polygons.find(s => s.id === geo.id);

                            let color = geo.properties.name == this.state.selectedRegion ? "#E42" : "#CC0000";
                            if((this.state.selectedPolygon)){
                                this.state.regionsScores.map((item, index)=>{
                                    if(item.name === geo.properties.name.substring(0, 2)){
                                        color = item.color;
                                    }
                                })
                            }else{
                                color = geo.properties.name == this.state.selectedRegion ? "#E42" : "#CC0000";
                            }
                            return (
                                <Fragment>
                                    {cur &&
                                        <Geography
                                            key={geo.rsmKey}
                                            stroke="#cc000066"
                                            geography={geo}
                                            //fill = {color}
                                            fillOpacity={
                                                this.state.selectedPolygon ?
                                                1 : cur.value
                                            }
                                            onClick={()=>this.getStoresBySelectedRegion(cur.properties.name)}
                                            style={{
                                                default: {
                                                fill: color,
                                                outline: "none"
                                                },
                                                hover: {
                                                fill: "#E42",
                                                outline: "none",
                                                cursor: "pointer"
                                                },
                                                pressed: {
                                                fill: "#E42",
                                                outline: "none"
                                                }
                                            }}
                                        />
                                    }
                                </Fragment> 
                            );
                            }
                        )}

                        {geographies.map(geo => {
                            const centroid = geoCentroid(geo);
                            const cur = this.state.polygons.find(s => s.id === geo.id);
                          
                            return (
                                <g key={geo.rsmKey + "-name"}>
                                    {(cur && this.state.selectedPolygon) &&
                                        <Marker coordinates={centroid}>
                                            {this.state.regionsScores.map((item, index)=>{
                                                if(item.name+"A2" === cur.properties.name){
                                                    return(
                                                        <Fragment>
                                                            <text y={getOffset(cur.properties.name).y} x={getOffset(cur.properties.name).x} fontSize={15} textAnchor="middle" style={{pointerEvents: "none"}}>
                                                                {item.name}
                                                            </text>
                                                            <text y={16 + getOffset(cur.properties.name).y} x={getOffset(cur.properties.name).x} fontSize={22} textAnchor="middle" style={{pointerEvents: "none"}}>
                                                                {item.score}
                                                            </text>
                                                        </Fragment>   
                                                    )
                                                }
                                            })}
                                        </Marker>
                                    }
                                    {(cur && !this.state.selectedPolygon) &&
                                        <Marker coordinates={centroid}>
                                            <text y={getOffset(cur.properties.name).y} x={getOffset(cur.properties.name).x} fontSize={10} textAnchor="middle" style={{pointerEvents: "none"}}>
                                                {cur.properties.name}
                                            </text>
                                            <text y={16 + getOffset(cur.properties.name).y} x={getOffset(cur.properties.name).x} fontSize={18} textAnchor="middle" style={{pointerEvents: "none"}}>
                                                {cur.score}
                                            </text>
                                        </Marker>
                                    }
                                </g>
                            );
                        })}
                    </>
                    )}
                </Geographies>
                </ComposableMap>
                <div className="description">{this.state.description}</div>
            </div>
            <div id="survey-open-map">
                {/* {this.state.defMap == true &&
                    <OpenMap coordinates={this.state.coordinates}/>
                } */}
                {this.state.defMap == false &&
                    <OpenMap coordinates={this.state.routes} getMarkerData={this.getMarkerData} setPolygon={this.setPolygon} polygons={this.state.polygons}/>
                }
                {this.state.markerData.length > 0 &&
                    <div className="marker-data">
                        <p><b>Podaci o odabranoj prodavnici:</b></p>
                        <p>Store Doncafe code: {this.state.markerData[0].storeDoncafeCode}</p>
                        <p>Territory: {this.state.markerData[0].territory}</p>
                        <p>Cooperation: {this.state.markerData[0].cooperation}</p>
                        <p>Score: {this.state.markerData[0].score}</p>
                        <p>Value: {this.state.markerData[0].value+" "+this.state.markerData[0].name}</p>
                        <p>Store name: {this.state.markerData[0].storeName}</p>
                        <p>Address: {this.state.markerData[0].storeAddress}</p>
                    </div>   
                }
            </div>
        </div>
        <div className="survey-table">
            <table>
                <tr>
                    <th>Territory</th>
                    <th>Score</th>
                    <th>N<sup>o</sup> stores</th>
                </tr>
                {this.state.polygons.map((item, index)=>{
                    return(
                        <tr>
                            <td><span className="survey-td-bck" style={{opacity: item.value}}></span><span>{item.territory}</span></td>
                            <td><span className="survey-td-bck" style={{opacity: item.value}}></span><span>{item.score}</span></td>
                            <td><span className="survey-td-bck" style={{opacity: item.value}}></span><span>{item.scoreCount}</span></td>
                        </tr>
                    )
                })}
                {this.state.regionsScores.map((item, index)=>{
                    return(
                        <tr>
                            <td colspan="2"><span className="survey-td-bck" style={{opacity: item.score}}></span><span>{item.name}</span></td>
                            <td><span className="survey-td-bck" style={{opacity: item.score}}></span><span>{item.score}</span></td>
                        </tr>
                    )
                })}
                {this.state.totalScore &&
                    <tr>
                        <td colspan="2"><span className="survey-td-bck" style={{opacity: 1}}></span><span>Total:</span></td>
                        <td><span className="survey-td-bck" style={{opacity: 1}}></span><span>{this.state.totalScore}</span></td>
                    </tr>
                }
            </table>
        </div>
      </div>
      
    )
  }
}

export default Map;