import React, { Component } from 'react';
import Service from '../components/Service';
import {Data} from '../components/Data';
import { CSVReader } from 'react-papaparse';
import { tr } from 'react-dom-factories';


const items = {
    data: []
};
class ImportCustomCsv extends Component {
constructor() {
    super();
    this.state = {
      name: '',
      preview: [],
      selectedData: null
    };
    this.fileInput = React.createRef();
  }

   
  
  showFile = () => {
    if (window.File && window.FileReader && window.FileList && window.Blob) {
         var preview = document.getElementById('show-text');
         var file = document.querySelector('input[type=file]').files[0];
         var reader = new FileReader()

         var textFile = /text.*/;

        //  var state = this.state;

         if (file.type.match(textFile)) {
             let data = [];
            reader.onload = function (event) {

                items.data = event.target.result.split(/\r?\n/);
                console.log(items.data);
               //preview.innerHTML = items.data.slice(1,10);//event.target.result.slice(0,50000);
               //data = partners;
               //state.setState({partners: items},()=>{
               //console.log(state.partners);
            //    });
               
               //console.log(event.target.result.slice(0,500000).split(/\r?\n/));
               //Service.get('/test/parseSellin',{sellin: event.target.result.slice(0,50000)});
            }
         } else {
            preview.innerHTML = "<span class='error'>It doesn't seem to be a text file!</span>";
         }
         reader.readAsText(file);

   } else {
      alert("Your browser is too old to support HTML5 File API");
   }
  }

    handleReadCSV = (data) => {
        console.log(data);
        // this.setState({
        //         sellOut: data.data
        //     });
        items.data = data.data;
        this.showPreview(items.data);
        console.log(data.data);
    }

    handleImportOffer = () => {
      console.log('this.fileInput.current', this.fileInput.current)
        this.fileInput.current.click();
        
    }

    

    showPreview=(data)=>{
        let parsedDataForth = [];
        let parsedDataBack = [];
        parsedDataForth.push(data.slice(0,6));
        parsedDataBack.push(data.slice(data.length - 5, data.length));
        let parsedData = parsedDataForth[0].concat(parsedDataBack[0]);
        this.setState({preview: parsedData}, ()=>{

          console.log('test', this.state.preview);
        });
        //Data.data = data;
        this.setState({selectedData: data});
       
        console.log('Data:',Data);
   
    }
    handleName = (event) => {
      console.log(event.target.value);
      this.setState({name: event.target.value});
    }
    saveData=()=>{

      let data = {name: this.state.name, data:  this.state.selectedData};

      Data.addDataSet(data);
      console.log('Data:',Data);
   
    }
    
  componentDidMount(){
    console.log(this.props.name);
  }

  render() {
    return (
      <div>
        <h2>IMPORT CUSTOM CSV ROUTE</h2>
        {/* <input type="file" onChange={this.showFile} /> */}
        <CSVReader
          onFileLoaded={this.handleReadCSV}
          inputRef={this.fileInput}
          style={{display: 'none'}}
          onError={this.handleOnError}
        />
        <p className='hint' >Klikni dugme 'Odaberi CSV' da uneses proizvode iz CSV fajla</p>

        <button onClick={this.handleImportOffer}>Odaberi CSV</button>
        
        <input value={this.state.name} onChange={this.handleName} />
        <button onClick={this.saveData}>Posalji</button>
        {/* <div id="show-text">Choose text File</div> */}
        <div>
          <table>
            <tr>
              {this.state.preview.map(function(item, index){
                if(index == 0){
                  return(
                    item.map(function(it, i){
                      return(
                        <th>{it}</th>
                      )
                    })
                  )
                }
              })}
            </tr>
            { 
              this.state.preview.map(function(item, index){
                if(index > 0){
                  return(
                    <tr>
                    {item.map(function(it, i){
                      return(
                        <td>{it}</td>
                      )
                    })}
                    </tr>  
                  )
                }
              })
            
            }
          </table>
        </div>
        
        {/* <button onClick={this.state.showFile}>Pregledaj</button> */}  
      </div>
    );
  }

}

export default ImportCustomCsv;