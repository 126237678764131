import React, { Component } from 'react';
import Chart from '../components/Chart';
import Service from '../components/Service';

class Analisys extends Component {

	constructor(props) {
      super(props);
      
      this.state = { 
        charts: [],
        filters: {}
      }

  }

  componentDidMount(){
      this.getFiters();
  }
  
  addChart = (chartNewName) => {
    let cs = this.state.charts;

    cs.push(chartNewName);
    this.setState({charts: cs});
  }

  addNewChart = () => {
    var chartName = prompt("Unesi ime grafikona", "Grafikon " + (this.state.charts.length + 1));
    if (chartName != null) {
      this.addChart(chartName);
    }

  }

  removeChart = (index) => {
    console.log(index);
    let cs = this.state.charts;

    cs.splice(index, 1);
    console.log(cs);
    this.setState({charts: cs});

  }

  getFiters =()=>{
      Service.get('/analytics/getFilters',{},(res)=>{
          this.setState({filters: res.data})
      })
  }

  render() {
    return (
      <div className="content"> 
        <h1>Analiza prodaje:</h1>
        <p>{this.props.match.params.lang}</p>
        {/* <Chart chartName={'Sentilytics Dev Chart'} /> */}
        
        {this.state.charts.map(function(item, index){
          return(
            <div className='chart-container' key={index}>
            <Chart chartName={item} filters={this.state.filters}/>
            <button className="keyword" onClick={() => this.removeChart(index)}>- Ukloni grafikon</button>
            </div>
          )
        }, this)}

        {/* ADD NEW CHART */}
        <div className='add-chart-button-cont'>
          <button className="keyword" onClick={() => this.addNewChart()}>+ Dodaj novi grafikon</button>
        </div>
        
      </div>
      
    )
  }
}

export default Analisys;