import React, { Component } from 'react';
import HighchartsReact from "highcharts-react-official";
import highcharts from 'highcharts'
import { DatePicker } from '@y0c/react-datepicker';
import moment from 'moment';
import Service from '../components/Service';
import DataSet from '../components/Dataset';
import DateSelector from '../components/DateSelector';
import duix from 'duix';


import '@y0c/react-datepicker/assets/styles/calendar.scss';
//import { ItemContent } from 'semantic-ui-react';

//const Chart1 = ({ options, highcharts }) => (
class Chart1 extends Component {

  constructor(props) {
    super(props);
      this.state = { 
        options: {
          chart: {
            type: 'column',
            plotBorderWidth: 1,
            zoomType: 'xy'
          },
          xAxis: {
            categories: [],
          },
          title: {
            text: this.props.chartName
          },
          series: []
        },

        products: [],
        productsObject: {},

        groupBy: ['day','month','products','customers'],
        dataSets: [],
        chartTypes: ['line', 'column', 'pie'],
        selectedChartType: 'line',
        selectedDatasets: [],
        selectedGroup: '',
        startDate: '',
        endDate: ''
        
      };
  }

  componentDidMount(){
      this.getProducts();
  }

  getProducts=()=>{
      Service.get('/analytics/getProducts',{},(res)=>{
          let ds = []
          let pa = {}
          res.data.products.map(function(item, index){
              ds.push(item.barcode);
              pa[item.barcode] = item.name;
          })
            console.log('pa',pa);
            this.setState({productsObject: pa});
        //   this.setState({dataSets: ds});
      })
  }


  changeGroupBy = (event) => {
    this.setState({selectedGroup: event.target.value});
    duix.set('update_dataset', 0);
  }

  handleChangeStart = (date) => {
    console.log('date', date);
    this.setState({startDate: date},()=>{
        duix.set('update_dataset', 0);
    });
  }

  handleChangeEnd = (date) => {
    this.setState({endDate: date},()=>{
        duix.set('update_dataset', 0);
    });
  }

  

  changeDataset = (event) => {
    let sds = this.state.selectedDatasets;

    //let opt = this.state.options;
    var exists = 0;
    for( var i=0;i<sds.length;i++){ 
      if ( sds[i] == event.target.value) {
        console.log('dataset already added');
        return;
      }
    }

    sds.push(sds.length);
    console.log('eid',sds.length)
    this.setState({selectedDatasets: sds})
    // this.getDataset(event.target.value, this.state.productsObject[event.target.value]);
  }


//   addDataset = (dataSet, data) => {

//     let opt = this.state.options;
//     var exists = 0;
//     for( var p in opt.series){ 
//       if ( opt.series[p].name == dataSet) {
//         console.log('dataset already added');
//         return;
//       }
//     }

//     opt.series.push(this.createDataSet(dataSet, data));
//     this.setState({options: opt});
//     console.log(this.state);
//   }

    setXValues=(data)=>{
        let opt = this.state.options;
        opt.xAxis.categories = data;

        this.setState({options: opt});
    }

    updateDataset = (filters, index) => {
        console.log('update: ', filters, index);

            Service.get('/analytics/getSellData',{filters: {
                    from: this.state.startDate, 
                    to: this.state.endDate, 
                    storeId: filters.storeId,
                    productId: filters.productId,
                    straussCode: filters.straussCode,
                    businessAssociateId: filters.businessAssociateId,
                    type: filters.type,
                    customerId: filters.customerId,
                    name: filters.name,
                    groupBy: this.state.selectedGroup,
                    canceled: filters.canceled,
                    products: filters.products,
                    prediction: filters.prediction,
                    salesChannels: filters.salesChannels

                }},(res)=>{
                console.log(res);
                console.log('index', index);

                let opt = this.state.options;
                opt.series[index] = {name: filters.name, data: res.data.amounts}
                //opt.series[index] = {name: filters.name, data: [55,66,33,44,66,22,55,33,55,44]}
                this.setState({options: opt});
                
                this.setXValues(res.data.dates);
                //this.setXValues(['535','vojno','535','vojno','535','vojno']);

            })

    }

//   createDataSet(dataSet, data){
//     console.log('data: ',data, dataSet);
//     let ds = [];
//     data.map(function(item){
//       ds.push(item.value);
//     })
//     // for(var i=0;i<14;i++){
//     //   ds.push(Math.round(Math.random() * 100));
//     // }
//     return {name: dataSet, data: data};
//   }

    removeDataset = (index) => {
        console.log('remove dataset ' + index);
        let sd = this.state.selectedDatasets;
        console.log('selected datasets', sd);
        sd.splice(index, 1); 

        this.setState({selectedDatasets: sd},function(){

            //remove datasets from the chart object
            let opt = this.state.options;
            opt.series.splice(index, 1);

            this.setState({options: opt});
            // console.log('options', this.state.options);

        });
    }

  changeChartType = (event) => {
    // let opt = this.state.options;
    // opt.chart.type = event.target.value;
    // this.setState({options: opt});
    // this.setState({selectedChartType: event.target.value});
  }




  getDataset = (dataset, name) => {
    console.log('triggered');

    Service.get('/analytics/getChartData',{from: this.state.startDate, to: this.state.endDate, barcode: dataset},(res)=>{
        console.log(res);
        this.addDataset(name, res.data.amounts);
        this.setXValues(res.data.dates);
    })
    
  }

  
changeFrom=(date)=>{
    this.setState({startDate: date});
    console.log('start date',this.state.startDate);
}

changeTo=(date)=>{
    this.setState({endDate: date});
    console.log('end date',this.state.endDate);
}


  render() {

    return (
      <div>
        <div className="chart">
            <div className="chart-controls">
                <DateSelector label="Od" onChange={this.changeFrom} defaultYear={2018} defaultMonth={1} />
                <DateSelector label="Do" onChange={this.changeTo} defaultYear={2020} defaultMonth={1} />

            {/* GROUP BY */}
            <select value={this.state.selectedGroup} onChange={this.changeGroupBy}>
            <option >Group By:</option>
                {this.state.groupBy.map(function(item, index){
                return(
                    <option value={item} key={index}>{item}</option>
                )},this
                )};
            </select>

            {/* CHART TYPE */}
            {/* <select value={this.state.selectedChartType} onChange={this.changeChartType}>
            <option >Tip grafikona:</option>
                {this.state.chartTypes.map(function(item, index){
                return(
                    <option value={item} key={index}>{item}</option>
                )},this
                )};
            </select> */}

            {/* DATA SETS */}
            {/* <select value={this.state.selectedDataset} onChange={this.changeDataset}>
            <option >dodaj proizvod:</option>
                {this.state.dataSets.map(function(item, index){
                return(
                    <option value={item} key={index}>{this.state.productsObject[item]}</option>
                )},this
                )};
            </select> */}
           
          </div>
          <div className='datasets-container'>
                {this.state.selectedDatasets.map(function(item, index){
                return(
                    <DataSet 
                        item={item} 
                        key={index} 
                        index={index} 
                        filters={this.props.filters} 
                        removeDataset={this.removeDataset} 
                        updateDataset={this.updateDataset}
                    />
                    
                    // <div className="keyword" key={index} >
                    //     <DataSet item={item} key={inex}/>
                    //   {item}
                    //   <button className="keyword-delete-button" onClick={() => this.removeDataset(item)} key={item.id} >X</button>
                    //   <button className="keyword-delete-button" onClick={() => this.updateDataset(item, index)} key={item.id} >U</button>
                    // </div>
                )},this
                )}
                <buton className="keyword" id="button" onClick={this.changeDataset}>+ Dodaj Dataset</buton>
          </div>
            <div className='chart-container-in'>
                <HighchartsReact highcharts={highcharts} options={this.state.options} allowChartUpdate={true}
                updateArgs={[true, true, true]}/>
            </div>
        </div>
      </div>
    )
  }

}

export default Chart1;
