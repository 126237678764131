import React, { useState } from "react";
import {Data} from '../components/Data';
import * as XLSX from "xlsx";
import { data } from "react-dom-factories";

function ImportCustomExcel() {
  const [items, setItems] = useState([]);
  const [name, setName] = useState('');

  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
    
    promise.then((d) => {
        let keys = Object.keys(d[0]);
        let newArray = [];
        newArray.push(keys.map(String));
        console.log(keys);
        d.map((item, index)=>{
          newArray.push(Object.values(d[index]).map(String));
        });
        setItems(newArray);
        //console.log("items", items);
      
    });
    
  };

  const setDataName = (event) =>{
    setName(event.target.value);
  }

  const saveData = () => {
    let data = {name: name, data: items};
    Data.addDataSet(data);
    console.log('Data data:',Data);
  }
  

  return (
    <div>
      <input
        type="file"
        onChange={(e) => {
          const file = e.target.files[0];
          readExcel(file);
        }}
      />
      <div>
        <input value={name}  onChange={setDataName}/>
        <button onClick={saveData}>Posalji</button>
      </div>
      <p>{name}</p>

      {/* <table className="table container"> */}

        <div>
            <table>
              <tr>
                {items.map(function(item, index){  
                  if(index == 0){
                    return(
                      item.map(function(it, i){ 
                        return(
                          <th>{it}</th>
                        )
                      })
                    )
                  }
                })}
              </tr>
              {items.map(function(item, index){
                  if(index > 0 && item){
                    return(
                      <tr>
                      {item.map(function(it, i){
                        return(
                          <td>{it}</td>
                        )
                      })}
                      </tr>  
                    )
                  }
                })
              }
            </table>
          </div>
        </div>
        /* <thead>
          <tr>
            <th scope="col">Item</th>
            <th scope="col">Description</th>
          </tr>
        </thead>
        <tbody>
          {items.map((d) => (
            <tr key={d.Item}>
              <th>{d.title}</th>
              <td>{d.price}</td>
            </tr>
          ))}
        </tbody>
      </table> */
  
  );
}

export default ImportCustomExcel;