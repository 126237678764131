import React, { Component } from 'react';
import L from 'leaflet';
import {Map, TileLayer, Marker, Popup, Text, FeatureGroup, Circle} from 'react-leaflet';
import { EditControl } from "react-leaflet-draw"
// import icon from '../images/map-icon.png';

class OpenMap extends Component {
    state = { 
        zoom: 7,
        mapData:[]
    }

    componentWillReceiveProps(props){
        this.setState({mapData: props.coordinates},()=>{
            ////console.log("md", this.state.mapData)
        })
    }

  
    getMarker=(id)=>{
        //console.log("id", id);
        this.props.getMarkerData(id);
    }

    _onCreate=(event)=>{
        let c = event.layer.editing.latlngs[0][0];
        ////console.log('fm', event.layer.editing.latlngs[0][0]);
        let res = [];
        c.map((item)=>{
            //console.log('item', item.lat, item.lng);
            res.push([item.lng, item.lat])
        })
        this.props.setPolygon(res);
    }

    mapClick=(e)=>{
        //console.log(e);
    }
  
    render() {
        ////console.log("c", this.state.mapData)
        // const centerMap = [this.props.coordinates[0].lat, this.props.coordinates[0].lng];
        const centerMap = this.state.mapData[1];
        return (
          <Map className="leaflet-map" center={centerMap} zoom={this.state.zoom} onClick={this.mapClick}>
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {this.state.mapData && 
                this.state.mapData.map(function (item, index) {
                    const position = [item.lat, item.lng];
                    let is = [7,7];
                    if(item.iconSize){
                        is = item.iconSize;
                    }else{

                    }
                    const mapIcon = L.divIcon({
                        className: item.class,
                        iconAnchor: [3, 3],
                        iconSize: [7, 7],
                        
                    })
                    return (
                       // <Marker position={position} icon={mapIcon} key={index} title="static marker" onMouseOver={(e) => {e.target.openPopup();}} onMouseOut={(e) => {e.target.closePopup();}}>
                        <Marker position={position} icon={mapIcon} key={index} onClick={()=>{this.getMarker(item.id)}}>
                            {/* <Text>"static marker"</Text> */}
                            <Popup>
                                <span>{item.label}</span>
                            </Popup>
                        </Marker>
                )},this)
            }   

            <FeatureGroup>
                <EditControl
                position='topright'
                onEdited={this._onEditPath}
                onCreated={this._onCreate}
                onDeleted={this._onDeleted}
                draw={{
                    rectangle: false
                }}
                />
                <Circle center={[51.51, -0.06]} radius={200} />
            </FeatureGroup>
          </Map>        
        )
    }
}


export default OpenMap;