import React, { Component } from 'react';
import { CSVReader } from 'react-papaparse';

const API = process.env.REACT_APP_API;

function CsvEmpty(props) {
  if (props.books.length === 0) {
    return <p className='hint'>Trenutno nije odabran CSV</p>;
  }
  return null;
}

function images(image){
  return 'images/'+ image + '.jpg';
}

class ProductsImport extends Component {

    constructor(props) {
    super(props);
    this.fileInput = React.createRef();
    this.state = {books: []}
    this.prepareProducts = this.prepareProducts.bind(this);
    this.createProducts = this.createProducts.bind(this);
    
  }

  handleReadCSV = (data) => {
    console.log(data);
    this.setState({
            books: data.data
          });
    //console.log(data.data[0]);
  }

  handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  }

  handleImportOffer = () => {
    this.fileInput.current.click();
  }

  relations = [
    {title: "ime"},
    {author: "autor"},
    {translator: "prevod"},
    {number_of_pages: "broj_strana"},
    {isbn: "isbn"},
    {dimension: "format"},
    {weight: "tezina"},
    {binding: "povez"},
    {letter: "pismo"},
    {slug: "slug"},
    {tax: "porez"},
    {price: "cena"}
  ];

  createProducts(products){
    fetch(`${API}/import_csv`,
      {
        method: 'post',
        headers: {'Content-Type':'application/x-www-form-urlencoded'},
        body: JSON.stringify({
          products: products
        })
       })
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result);
          this.setState({
            books: []
          })
        },
        (error) => {
          this.setState({
            // isLoaded: true,
            // error
          });
        }
      )
  }

  prepareProducts(){
    //let pr = {id: 1, name: 'test product'};
    //this.createProducts(pr);
    //return;

    let relations = {
      store_name: "store_name",
      product_name: "product_name",
      product_sku: "product_sku",
      sell_out: "sell_out",
      date: "date"
    };
    console.log(relations);

    let relationsHeads = {};

    let products = [];
    this.state.books.map(function(item, index){

      if(index === 0){

        // iterate through first row to reference the correct names 
        // and translate names to be db compatibile
        item.map(function(h, i){
          relationsHeads[i] = relations[h];
        });

      }else{

        let product = {};
        item.map(function(h, i){
          //relationsHeads[i] = relations[h];
          product[relationsHeads[i]] = h;
        });
        products.push(product);
      }

    });
    console.log('result');
    console.log(products);

    this.createProducts(products);

  }

  render() {
    return (
      <div>
        <CSVReader
          onFileLoaded={this.handleReadCSV}
          inputRef={this.fileInput}
          style={{display: 'none'}}
          onError={this.handleOnError}
        />
        <h1>Unos Proizvoda:</h1>
        <p className='hint' >Klikni dugme 'Odaberi CSV' da uneses unose iz CSV fajla</p>
        <button onClick={this.handleImportOffer}>Odaberi CSV</button>
        <p className='hint' >Proizvodi će se pojaviti u sekciji 'Unosi'</p>
        <h2>Unosi: </h2>
        <CsvEmpty books={this.state.books} />
        <table>
          <tbody>
          {this.state.books.map(function(item, index){
            console.log(item);
            //let imageUrl = 'images/'+ item[0] + '.jpg';
            return(
                
                  
                      <tr key={index} >
                        {/* <img src={images(item[0])} /> */}
                        {item.map(function(itemIn, i){
                          return(
                              <td key={i}>{itemIn}</td>
                           );
                        })}
                      </tr>
              );
          })}
          </tbody>
        </table>
        <button onClick={this.prepareProducts}>Snimi proizvode</button>
      </div>
    );
  }

}

export default ProductsImport;
