import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App'; 
import '../node_modules/leaflet/dist/leaflet.css';
//import * as serviceWorker from './serviceWorker';

const styleLink = document.createElement("link");
styleLink.rel = "stylesheet";
document.head.appendChild(styleLink)

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
